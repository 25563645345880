// ./modals/ClipPreviewModal.js
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faOctagonXmark, faDownload, faUpload } from '@fortawesome/pro-solid-svg-icons';
import '../../../css/tables.css'; 

const ClipPreviewModal = ({
  isOpen,
  onRequestClose,
  isLoading,
  clipUrl,
  onDownloadClick: originalOnDownloadClick,
  onUploadClick,
  selectedResult,
  isAudioOnly = false
}) => {
  const [showFilenameInput, setShowFilenameInput] = useState(false);
  const defaultWithoutExtension = selectedResult?.video_id?.replace(/\.(mp4|mp3)$/, '') || 'clip';
  const [filename, setFilename] = useState(defaultWithoutExtension);
  const extension = isAudioOnly ? '.mp3' : '.mp4';

  useEffect(() => {
    if (isOpen) {
      setFilename(defaultWithoutExtension);
      setShowFilenameInput(false);
    }
  }, [isOpen, defaultWithoutExtension]);

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/\.(mp4|mp3)$/i, '');
    setFilename(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const finalFilename = filename.trim().endsWith(extension) 
      ? filename.trim() 
      : `${filename.trim()}${extension}`;
    originalOnDownloadClick(finalFilename);
    setFilename('');
    setShowFilenameInput(false);
  };

  const handleDownloadClick = (e) => {
    e.preventDefault();
    setShowFilenameInput(!showFilenameInput);
  };

const handleClose = () => {
  if (showFilenameInput) {
    // If we're inputting a filename, just close the input
    setShowFilenameInput(false);
    setFilename(defaultWithoutExtension);
  } else {
    // Otherwise, close the whole modal
    onRequestClose();
  }
};


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="react-modal2"
      overlayClassName="clip-modal-overlay"
    >
      <div className="modal-header2 close-container2">
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', backgroundColor: 'transparent' }}>
      <FontAwesomeIcon 
        icon={faOctagonXmark}
        className="modal-close-icon"
        style={{ color: 'red', marginLeft: '20px' }}
        onClick={handleClose}
      />
        {!isLoading && (
          <>
            {!showFilenameInput ? (
              // Only show icons when not inputting filename
              <>
                <FontAwesomeIcon
                  icon={faDownload}
                  className="modal-download-icon"
                  style={{ color: 'blue', marginLeft: '10px', marginRight: '10px' }}
                  onClick={handleDownloadClick}
                />
                <FontAwesomeIcon
                  icon={faUpload}
                  className="modal-upload-icon"
                  style={{ color: 'green', marginLeft: '10px', marginRight: '10px' }}
                  onClick={onUploadClick}
                />
              </>
            ) : (
              // Show form when inputting filename
              <form 
                onSubmit={handleSubmit}
                style={{ 
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '10px',
                  flex: 1,
                  maxWidth: '500px'
                }}
              >
                <div style={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  flex: 1,
                  backgroundColor: 'transparent'
                }}>
                  <span style={{
                    color: 'rgb(102, 102, 102)',
                    fontSize: '0.9em',
                    marginRight: '8px',
                    '--darkreader-inline-color': '#9a9093'
                  }}>
                    filename:
                  </span>
                  <input
                    type="text"
                    value={filename}
                    onChange={handleInputChange}
                    className="p-1 text-sm flex-grow bg-transparent"
                    placeholder=""
                    autoFocus
                    style={{ 
                      border: 'none',
                      outline: 'none'
                    }}
                  />
                  <span style={{ 
                    color: 'rgb(102, 102, 102)',
                    fontSize: '0.8em',
                    marginRight: '8px',
                    '--darkreader-inline-color': '#9a9093'
                  }}>
                    {extension}
                  </span>
                </div>
                <button
                  type="submit"
                  className="ml-2 px-3 py-1 bg-blue-500 text-white rounded text-sm hover:bg-blue-600"
                >
                  finish
                </button>
              </form>
              )}
            </>
          )}
        </div>
      </div>
      {isLoading ? (
        <p>Generating - please wait...</p>
      ) : (
        <ReactPlayer 
          url={clipUrl} 
          controls={true} 
          playing={true} 
          volume={0.90} 
          width='100%' 
        />
      )}
    </Modal>
  );
};

export default ClipPreviewModal;