import React, { useState, useEffect, useContext } from 'react'; // Add useContext here
import ReactDOM from 'react-dom';
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import ReactGA from "react-ga4";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faOctagonXmark, faUpload, faChevronRight, faChevronsRight, faChevronLeft, 
         faChevronsLeft, faArrowLeftToLine, faArrowRightToLine, faScrollOld, 
         faDownload } from '@fortawesome/pro-solid-svg-icons';
import { faScissors } from '@fortawesome/pro-duotone-svg-icons';
import { AuthContext, AuthProvider } from '../../AuthContext'; 
import { SearchResultsTable } from './components/SearchResultsTable';
import * as handlers from './components/Handlers.js';
import * as utils from './utils'
import '../../css/tables.css'; 
import { YouTubeUploadModal } from './modals/YouTubeUploadModal';

import VideoModal from './modals/VideoModal';
import ClipModal from './modals/ClipModals';
import ArchivalModal from './modals/ArchivalModal';
import ClipPreviewModal from './modals/ClipPreviewModal';
import ArchivalStatusModal from './modals/ArchivalStatusModal';

const SearchResults = ({ searchText, searchText2, channel_nickname, startDate, endDate }) => {
const [allDistinctChannelNicknames, setAllDistinctChannelNicknames] = useState([]);
const [ArchivalModalOpen, setArchivalModalOpen] = useState(false);
const [audioOnly, setAudioOnly] = useState(false);
const [cameFromClipModal, setCameFromClipModal] = useState(false);
const [clipDuration, setClipDuration] = useState(30);
const [clipModalOpen, setClipModalOpen] = useState(false);
const [currentPage, setCurrentPage] = useState(1);
const [customFilename, setCustomFilename] = useState('');
const defaultRequestNote = '';
const element = <FontAwesomeIcon icon={faOctagonXmark} size="lg" />;
const [filteredResults, setFilteredResults] = useState([]);
const [filterText, setFilterText] = useState('');
const [initialSelectedChannelNicknames, setInitialSelectedChannelNicknames] = useState(new Set());
const [isLoading, setIsLoading] = useState(false);
const [requestNote, setRequestNote] = useState(defaultRequestNote);
const [responseMessage, setResponseMessage] = useState('');
const [responseStatus, setResponseStatus] = useState('');
const [results, setResults] = useState([]);
const [resultsPerPage] = useState(8);
const [selectedChannelNicknames, setSelectedChannelNicknames] = useState(initialSelectedChannelNicknames);
const [selectedResult, setSelectedResult] = useState(null);
const [selectedRow, setSelectedRow] = useState(null); // Track the selected row
const [showFilenameModal, setShowFilenameModal] = useState(false);
const [showVideoModal, setShowVideoModal] = useState(false);
const [showYoutubeUploadModal, setShowYoutubeUploadModal] = useState(false);
const [startTimeOffset, setStartTimeOffset] = useState(0);
const totalPages = Math.ceil(results.length / resultsPerPage);
const totalPagesFiltered = Math.ceil(filteredResults.length / resultsPerPage);
const { user } = useContext(AuthContext);
const [videoUrl, setVideoUrl] = useState('');
const [youtubeUploadData, setYoutubeUploadData] = useState({
  channel: 'dungeon',
  hashtags: '',
  schedule_time: 'now',
  video_description: '',
  video_title: '',
  video_type: 'regular',
});

const handleCloseModal = () => {
  handlers.handleCloseYoutubeUploadModal(
    setShowYoutubeUploadModal,
    cameFromClipModal,
    setShowClipModal,
    setCameFromClipModal
  );
};

const YouTubeUploadModal = ({ 
 isOpen, 
 onRequestClose, 
 onUpload, 
 initialData, 
 clipUrl 
}) => {
 const [formData, setFormData] = useState(initialData);

const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData(prevData => ({
    ...prevData,
    [name]: value
  }));
};

const handleSubmit = (e) => {
   e.preventDefault();
   onUpload(formData);
};

return (
   <Modal
     isOpen={isOpen}
     onRequestClose={onRequestClose}
     className="youtube-upload-modal"
     overlayClassName="youtube-upload-overlay"
    >
     <div className="modal-header2 close-container2">
       <FontAwesomeIcon 
         icon={faOctagonXmark} 
         className="modal-close-icon" 
         style={{ color: 'red', marginLeft: '20px' }} 
         onClick={onRequestClose}
       />
       <h2 style={{ marginLeft: '10px' }}>Upload to YouTube</h2>
     </div>

     <form onSubmit={handleSubmit} className="youtube-upload-form">
       <label>
         Video Title:
         <input 
           type="text" 
           name="video_title" 
           value={formData.video_title} 
           onChange={handleInputChange} 
           required 
         />
       </label>

       <label>
         Video Description:
         <textarea 
           name="video_description" 
           value={formData.video_description} 
           onChange={handleInputChange} 
           required 
         />
       </label>

       <label>
         Video Type:
         <select 
           name="video_type" 
           value={formData.video_type} 
           onChange={handleInputChange}
         >
           <option value="short">Short</option>
           <option value="regular">Regular</option>
         </select>
       </label>

       <label>
         Schedule Time:
         <select 
           name="schedule_time" 
           value={formData.schedule_time} 
           onChange={handleInputChange}
         >
           <option value="now">Now</option>
           <option value="1h">1 hour from now</option>
           <option value="6h">6 hours from now</option>
           <option value="12h">12 hours from now</option>
           <option value="24h">24 hours from now</option>
         </select>
       </label>

       <label>
         Hashtags CSV:
         <textarea 
           name="hashtags" 
           value={formData.hashtags} 
           onChange={handleInputChange} 
           optional 
         />
       </label>

       <label>
         Channel:
         <select 
           name="channel" 
           value={formData.channel} 
           onChange={handleInputChange}
         >
           <option value="dungeon">Dungeon</option>
           <option value="ac3">AC3</option>
           <option value="mookie">Mookie</option>
           <option value="hooligans">Hooligans</option>
         </select>
       </label>

       <button type="submit">Upload</button>
     </form>
   </Modal>
 );
};

ReactGA.send({ hitType: "pageview", page: "/transcripts", title: "search results" });

useEffect(() => {
 const filtered = results.filter(result =>
   selectedChannelNicknames.has(result.channel_nickname)
 );
 setFilteredResults(filtered);
 setCurrentPage(1);
}, [selectedChannelNicknames, results]);

useEffect(() => {
 setSelectedChannelNicknames(new Set(allDistinctChannelNicknames));
}, [allDistinctChannelNicknames]);

useEffect(() => {
 const { 
   searchText: innerSearchText, 
   searchText2: innerSearchText2, 
   channel_nickname, 
   startDate, 
   endDate 
 } = searchText;
 
 const channelData = JSON.parse(localStorage.getItem('channel-data') || '{"results":[]}');

 if (innerSearchText || innerSearchText2) {
   try {
     let channelLookupId;
     
     if (channel_nickname === "all") {
       channelLookupId = 0;
     } else {
       const entry = channelData.results.find(entry => entry[2] === channel_nickname);
       if (entry) {
         channelLookupId = entry[0];
       } else {
         console.error('Channel lookup_id not found for channel_nickname:', channel_nickname);
         return;
       }
     }

     const searchUrl = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/search?u=${user}&t=transcript&m=precision&c=${channelLookupId}&s=${innerSearchText}&s2=${innerSearchText2}&b=${startDate}&e=${endDate}&l=1000`;

     fetch(searchUrl)
       .then((response) => {
         if (!response.ok) {
           throw new Error('Network response was not ok');
         }
         return response.json();
       })
       .then((data) => {
         if (data.results && Array.isArray(data.results.transcript)) {
           const transcriptResults = data.results.transcript;
           
           if (transcriptResults.length) {
             const distinctChannelNicknames = new Set();
             data.results.transcript.forEach(item => {
               distinctChannelNicknames.add(item.channel_nickname);
             });

             setAllDistinctChannelNicknames(Array.from(distinctChannelNicknames));
             setResults(transcriptResults);
             setFilteredResults(transcriptResults);
             setCurrentPage(1);
             setFilterText('');
           } else {
             console.error('No results found:', transcriptResults);
             setResults([]);
             setFilteredResults([]);
           }
         } else {
           console.error('Invalid data structure:', data);
           setResults([]);
           setFilteredResults([]);
         }
       })
       .catch((error) => {
         console.error('Error fetching data:', error);
         setResults([]);
         setFilteredResults([]);
       });
     
   } catch (error) {
     console.error('Error in try block:', error);
   }
 }
}, [searchText, searchText2]);

const indexOfLastResult = currentPage * resultsPerPage;
const indexOfFirstResult = indexOfLastResult - resultsPerPage;

useEffect(() => {
  if (videoUrl) {
    setShowVideoModal(true);
    console.log('videoUrl:',videoUrl)
  }
}, [videoUrl]);

const convertTimeToSeconds = (time) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};


const [clipUrl, setClipUrl] = useState(null);
const [showClipModal, setShowClipModal] = useState(false);
const [showArchivalModal, setShowArchivalModal] = useState(false);

useEffect(() => {
  if (showClipModal) {
    setClipModalOpen(false);
  }
}, [showClipModal]);

useEffect(() => {
  if (showArchivalModal) {
    setArchivalModalOpen(false);
  }
}, [showArchivalModal]);

const handleRowMouseEnter = (index) => {
  setSelectedRow(index);
};

const handleRowMouseLeave = () => {
  setSelectedRow(null);
};

const closeVideoModal = () => {
  setShowVideoModal(false);
};

const extractVideoId = (url) => {
  const videoUrl = new URL(url);
  const videoId = videoUrl.searchParams.get('v');
  return videoId;
};

const handleKeyDown = (event) => {
  if (event.keyCode === 27) {
    closeVideoModal();
  }
};


const currentResults = filteredResults.slice(indexOfFirstResult, indexOfLastResult);

useEffect(() => {
  document.addEventListener('keydown', handleKeyDown);
  return () => {
    document.removeEventListener('keydown', handleKeyDown);
  };
}, []);

const videoModalOptions = {
    height: '480px',
    width: '854px',
    controls: true,
    playing: true,
  };

return (
  <div> 
    {/* Filter Input Section */}
    <div className="flex-container">
      <input
        type="text"
        value={filterText}
        onInput={(e) => handlers.handleFilterChange(e, setCurrentPage, results, setFilteredResults, setFilterText)}
        className="filter-input"
        placeholder="filter result"
      />
      {filteredResults.length > 0 ? (
        <div className="results-info" style={{ marginTop: '10px' }}>
          <p>{filteredResults.length} results</p>
        </div>
      ) : (
        <div className="results-info" style={{ marginTop: '10px' }}>
          <p style={{color: 'red'}}>No results found</p>
        </div>
      )}
    </div>

    {/* Channel Nicknames Section */}
    {allDistinctChannelNicknames.length > 0 && (
      <div>
        <div style={{display: 'flex', flexWrap: 'wrap', gap: '15px', paddingLeft: '25px'}}>
          {allDistinctChannelNicknames.map((channelNickname, index) => (
            <label key={index} style={{display: 'flex', alignItems: 'center'}}>
              <input
                type="checkbox"
                checked={selectedChannelNicknames.has(channelNickname)}
                onChange={() => handlers.handleChannelNicknameCheckboxChange(channelNickname, setSelectedChannelNicknames)}
              />
              {channelNickname}
            </label>
          ))}
        </div>
      </div>
    )}

    <br />

    {/* Pagination Section */}
    <div className="pagination-container">
      <div className="pagination">
        <div style={{ userSelect: 'none', display: 'inline-block' }}>
          <FontAwesomeIcon 
            icon={faArrowLeftToLine} 
            className="fa-thin icon-hover" 
            onClick={handlers.handlePreviousPageBeginningClick}
            disabled={currentPage === 1}
            style={{ 
              color: currentPage === 1 ? 'grey' : 'blue', 
              display: 'inline-block', 
              marginLeft: '10px'
            }} 
          />
          <FontAwesomeIcon 
            icon={faChevronLeft} 
            className="fa-thin icon-hover" 
            onClick={(event) => handlers.handlePreviousPageClick(event, currentPage, setCurrentPage)}
            disabled={currentPage === 1}
            style={{ 
              color: currentPage === 1 ? 'grey' : 'blue', 
              display: 'inline-block', 
              marginLeft: '10px'
            }} 
          />

          &nbsp; {currentPage} / {totalPagesFiltered}

          <FontAwesomeIcon 
            icon={faChevronRight} 
            className="fa-thin icon-hover" 
            disabled={currentPage >= totalPagesFiltered}
            onClick={(event) => handlers.handleNextPageClick(event, currentPage, totalPagesFiltered, setCurrentPage)}
            style={{ 
              color: currentPage + 1 > totalPagesFiltered ? 'grey' : 'blue',
              display: 'inline-block', 
              marginLeft: '10px'
            }} 
          />
          <FontAwesomeIcon 
            icon={faArrowRightToLine} 
            className="fa-thin icon-hover" 
            onClick={(event) => handlers.handleNextPageEndClick(event, totalPagesFiltered, setCurrentPage)}
            style={{ 
              color: currentPage + 1 > totalPagesFiltered ? 'grey' : 'blue',
              display: 'inline-block', 
              marginLeft: '10px'
            }} 
          />
        </div>
      </div>
    </div>

    {/* Search Results Table */}
    <SearchResultsTable
      currentResults={currentResults}
      handleRowClick={handlers.handleRowClick}
      handleRowMouseEnter={handleRowMouseEnter}
      handleRowMouseLeave={handleRowMouseLeave}
      selectedRow={selectedRow}
      handleClipButtonClick={handlers.handleClipButtonClick}
      handleTranscriptGeneration={handlers.handleTranscriptGeneration}
      handleArchivalRequest={handlers.handleArchivalRequest}
      searchText={searchText}
      filterText={filterText}
      setVideoUrl={setVideoUrl}
      setSelectedResult={setSelectedResult}
      setClipModalOpen={setClipModalOpen}
      setRequestNote={setRequestNote}
      setResponseMessage={setResponseMessage}
      setResponseStatus={setResponseStatus}
      setArchivalModalOpen={setArchivalModalOpen}
      defaultRequestNote={defaultRequestNote}
    />

      <VideoModal 
        showVideoModal={showVideoModal}
        videoUrl={videoUrl}
        videoModalOptions={videoModalOptions}
        closeVideoModal={closeVideoModal}
      />

      <ClipModal 
        isOpen={clipModalOpen}
        onRequestClose={() => setClipModalOpen(false)}
        selectedResult={selectedResult}
        startTimeOffset={startTimeOffset}
        setStartTimeOffset={setStartTimeOffset}
        clipDuration={clipDuration}
        setClipDuration={setClipDuration}
        audioOnly={audioOnly}
        setAudioOnly={setAudioOnly}
        handleClipGeneration={(selectedResult, startTimeOffset, clipDuration, audioOnly) => 
          handlers.handleClipGeneration(
            selectedResult,
            startTimeOffset,
            clipDuration,
            audioOnly,
            setIsLoading,
            setClipUrl,
            setShowClipModal
          )
        }
        isLoading={isLoading}
      />

      <ArchivalModal 
        isOpen={ArchivalModalOpen}
        onRequestClose={() => setArchivalModalOpen(false)}
        selectedResult={selectedResult}
        requestNote={requestNote}
        setRequestNote={setRequestNote}
        handleArchivalRequestGeneration={() => 
          handlers.handleArchivalRequestGeneration(
            selectedResult,
            requestNote,
            user,
            setResponseMessage,
            setResponseStatus
          )
        }
        isLoading={isLoading}
        responseMessage={responseMessage}
        responseStatus={responseStatus}
      />

      <ClipPreviewModal 
        isOpen={showClipModal}
        onRequestClose={() => setShowClipModal(false)}
        isLoading={isLoading}
        clipUrl={clipUrl}
        onDownloadClick={(filename) => handlers.handleDownloadClip(clipUrl, filename)}
        onUploadClick={(event) => handlers.handleYoutubeUploadClick(event, setCameFromClipModal, setShowYoutubeUploadModal)}
        selectedResult={selectedResult}
        isAudioOnly={audioOnly}
      />

      <ArchivalStatusModal 
        isOpen={showArchivalModal}
        onRequestClose={() => {
          setRequestNote(defaultRequestNote);
          setResponseMessage('');
          setResponseStatus('');
          setShowArchivalModal(false);
        }}
        isLoading={isLoading}
        handleArchivalRequest={handlers.handleArchivalRequest}
      />

      {showYoutubeUploadModal && ReactDOM.createPortal(
        <YouTubeUploadModal 
          isOpen={showYoutubeUploadModal}
          onRequestClose={handleCloseModal}
          onUpload={(formData) => handlers.uploadToYoutube(formData, clipUrl, setShowYoutubeUploadModal)}
          initialData={youtubeUploadData}
          clipUrl={clipUrl}
        />,
        document.body
      )}
  </div>
  );
}

export default SearchResults;



