// ./modals/VideoModal.js
import React from 'react';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faOctagonXmark } from '@fortawesome/pro-solid-svg-icons';
import '../../../css/tables.css'; 

const VideoModal = ({ showVideoModal, videoUrl, videoModalOptions, closeVideoModal }) => {
  if (!showVideoModal) return null;

  return (
    <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-body">
          <div className="modal-content">
            <div className="modal-header close-container" onClick={closeVideoModal}>
              <h6 className="modal-title">hit escape to close</h6>          
              <FontAwesomeIcon icon={faOctagonXmark} className="modal-close-icon" />
            </div>
            <ReactPlayer 
              url={videoUrl}
              width={videoModalOptions.width}
              height={videoModalOptions.height}
              controls={true}
              playing={true}
              onError={(e) => {
                console.error('Video error', e);
                closeVideoModal();
                window.open(videoUrl, '_blank');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;