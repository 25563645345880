// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    localStorage.getItem('pUser') || 
    (process.env.REACT_APP_BYPASS_AUTH === 'true' ? 'localUser' : null)
  );


 const login = async (username, password) => {
  try {
    const response = await axios.post('https://ica5ytvuvd.execute-api.us-west-2.amazonaws.com/production/login', {
      username,
      password,
    });

    console.log("Login response:", response); // Add this line for debugging

    const responseBody = JSON.parse(response.data.body); // This line causes the error

    if (responseBody.authenticated) {
      setUser(username);
      localStorage.setItem('pUser', username); // Persist user in localStorage
    } else {
      console.log('Login failed. Check your credentials.');
    }
  } catch (error) {
    console.error('Error during login:', error);
  }
};

  // Logout function
  const logout = () => {
    setUser(null);
    localStorage.removeItem('pUser'); // Remove user from localStorage
  };

  // Optionally, verify authentication status on mount
  useEffect(() => {
    const storedUser = localStorage.getItem('pUser');
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
