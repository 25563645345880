// ./modals/ClipModal.js
import React from 'react';
import Modal from 'react-modal';
import '../../../css/tables.css'; 

const ClipModal = ({ 
  isOpen, 
  onRequestClose, 
  selectedResult, 
  startTimeOffset,
  setStartTimeOffset,
  clipDuration,
  setClipDuration,
  audioOnly,
  setAudioOnly,
  handleClipGeneration,
  isLoading 
}) => {
  if (!selectedResult) return null;

  return (
    <Modal 
      isOpen={isOpen} 
      onRequestClose={onRequestClose}
      className="clip-modal-content"
      overlayClassName="clip-modal-overlay"
    >
      <h6>clip {selectedResult.video_title}</h6>
      <label>
        offset--s--(secs)
        <input 
          type="text" 
          value={startTimeOffset} 
          onChange={(e) => setStartTimeOffset(e.target.value)} 
        />
      </label>
      <label>
        duration-(secs)
        <input 
          type="number" 
          value={clipDuration} 
          onChange={(e) => setClipDuration(Number(e.target.value))} 
        />
      </label>
      <div className="flex items-center gap-2 mb-4">
        <input
          type="checkbox"
          id="audioOnly"
          checked={audioOnly}
          onChange={(e) => setAudioOnly(e.target.checked)}
          className="form-checkbox h-4 w-4 text-blue-600"
        />
        <label htmlFor="audioOnly" className="text-sm text-gray-700">
          Audio Only
        </label>
      </div>
      <button onClick={() => handleClipGeneration(
        selectedResult, 
        startTimeOffset, 
        clipDuration, 
        audioOnly
      )}>
        generate clip
      </button>
      {isLoading && <p>please wait...</p>}
    </Modal>
  );
};

export default ClipModal;