import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTable } from 'react-table';
//import { XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';
import { format, parseISO, startOfDay, subDays } from 'date-fns';
import './css/tables.css'; // Import the CSS file

const cellStyle = {
  whiteSpace: 'normal',
  wordWrap: 'break-word',
};

const formatXAxis = (tickItem) => {
  return format(parseISO(tickItem), 'yyyy-MM-dd');
};

const VideoChats = () => {
  let { videoId } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/get-chats?video_id=${videoId}`)
      .then((response) => response.json())
      .then((jsonData) => {
        const parsedData = JSON.parse(jsonData.results);

        setData(parsedData);
        
      })
      .catch((error) => console.error('Error fetching data: ', error));

  }, [videoId]);



  const columns = React.useMemo(
    () => [
      {
        Header: 'timestamp',
        accessor: 'timestamp',
        Cell: ({ value }) => format(new Date(value), 'yyyy-MM-dd HH:mm'),
        width: '25px',
      },
      {
        Header: 'author_id',
        accessor: 'author_id',
        width: '45px',
      },
      {
        Header: 'author_name',
        accessor: 'author_name',
        width: '35px'
      },
      {
        Header: 'message',
        accessor: 'message',
        width: '75px',
      },
      {
        Header: 'usd_value',
        accessor: 'usd_value',
        width: '25px',
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

return (
  <div>
    <br /><br />
    <h5 style={{textAlign: 'center'}}>chat history for video_id {videoId}</h5>
    <br />
    <div className="search-results-container">
    <div className="scrollbar-container" style={{ height: '500px', overflowY: 'auto' }}>

    <div style={{ height: '500px', overflowY: 'auto' }}>
      <table className="data" {...getTableProps()} style={{tableLayout: 'fixed', width: '100%'}}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={{...cellStyle, width: column.width}}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()} style={{...cellStyle, width: cell.column.width}}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </div>
  </div>
  </div>
);
};


export default VideoChats;



