export const MODAL_OPTIONS = {
  video: {
    height: '480px',
    width: '854px',
    controls: true,
    playing: true,
  },
};

export const TABLE_COLUMNS = [
  { id: 'channel', width: '10%', label: 'channel' },
  { id: 'video_id', width: '10%', label: 'video_id' },
  { id: 'thumbnail', width: '5%', label: 'thumbnail' },
  { id: 'publishedAt', width: '15%', label: 'publishedAt' },
  { id: 'video_title', width: '25%', label: 'video_title' },
  { id: 'start_time', width: '10%', label: 'start_time' },
  { id: 'text', width: '20%', label: 'text' },
  { id: 'timestamped_url', width: '5%', label: 'timestamped_url' },
  { id: 'actions', width: '10%', label: 'actions' },
];

export const INITIAL_YOUTUBE_UPLOAD_DATA = {
  video_title: '',
  video_description: '',
  video_type: 'regular',
  schedule_time: 'now',
  hashtags: '',
  channel: 'dungeon'
};