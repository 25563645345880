import React, { useState, useEffect, useContext } from 'react';
import { AuthContext, AuthProvider } from '../../AuthContext'; 
import {
  BarChart,
  Bar,
  Area,
  AreaChart,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LabelList
} from 'recharts';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Route } from 'react-router-dom';
import { Label } from 'recharts';
import { useTable } from 'react-table';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRobotAstromech  } from '@fortawesome/pro-solid-svg-icons'
const element = <FontAwesomeIcon icon={faRobotAstromech} />


const DataTable = ({ data }) => {
  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
  };

  const thStyle = {
    backgroundColor: '#f2f2f2',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '8px',
    borderBottom: '1px solid #ddd',
  };

  const tdStyle = {
    padding: '8px',
    borderBottom: '1px solid #ddd',
  };

const columns = React.useMemo(
  () => [
    {
      Header: 'rank',
      accessor: (row, index) => index + 1,
      disableSortBy: true,
    },
    {
      Header: 'donor',
      accessor: 'donor',
  Cell: ({ row }) => (
    <Link
      to={`/donorsummary/${row.original.donor_id}`}
      target="_blank"
    >
      {row.original.donor}
    </Link>
      ),
    },
    {
  Header: 'sum',
  accessor: row => parseFloat(row.sum_usd),
  Cell: ({ value }) => `$${value.toLocaleString(undefined, { maximumFractionDigits: 0 })}`, // To display the sum value with comma separators and no decimal places
},
  ],
  []
);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <table {...getTableProps()} style={tableStyle}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} style={thStyle}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()} style={tdStyle}>
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};


const Revenue = () => {
  const { user } = useContext(AuthContext);
  const [lineData, setLineData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [tableData6, setTableData6] = useState([]);
  const [tableData7, setTableData7] = useState([]);
  const [tableData8, setTableData8] = useState([]);


  const COLORS = ['#0088FE', '#13ab8e', '#37b067', '#d15f2e'];

  const channelColors = {
  wn: '#0088FE',
  ac3: '#0088FE',
  dungeon: '#0088FE',
  dis: '#0088FE',
  mookie: '#0088FE',
  jj: '#0088FE',
  timmy: '#37b067',
  community: '#c130d1',
  ocean: '#c130d1'
  };


  const lineDataWithDates = lineData.map(item => ({
    ...item,
    date: new Date(item.date),
  }))
  


  useEffect(() => {

fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/superchats?option=30_day_summary&clientip=127.0.0.1&username=${user}`)
  .then((response) => response.json())
  .then((jsonData) => {
    const parsedData = JSON.parse(jsonData.results).map((item) => ({
      ...item,
      alliance: item.wn + item.ac3 + item.dungeon + item.jj + item.mookie + item.blondee + item.marcelo,
      grifterverse: item.timmy + item.community + item.ocean,
      date: new Date(item.date).toLocaleDateString('en-CA'),
    }));
    setLineData(parsedData.reverse());
  })
  .catch((error) => console.error('Error fetching line chart data: ', error));


// Fetch data for Table 1
fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/superchats?option=30_day_top_donor_by_channel&channel_nickname=ac3&username=${user}`)
  .then((response) => response.json())
  .then((jsonData) => {
    const parsedData = JSON.parse(jsonData.results);
    setTableData1(parsedData);
  })
  .catch((error) => console.error('Error fetching data for Table 1: ', error));

// Fetch data for Table 2
fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/superchats?option=30_day_top_donor_by_channel&channel_nickname=wn&username=${user}`)
  .then((response) => response.json())
  .then((jsonData) => {
    const parsedData = JSON.parse(jsonData.results);
    setTableData2(parsedData);
  })
  .catch((error) => console.error('Error fetching data for Table 2: ', error));

// Fetch data for Table 3
fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/superchats?option=30_day_top_donor_by_channel&channel_nickname=timmy&username=${user}`)
  .then((response) => response.json())
  .then((jsonData) => {
    const parsedData = JSON.parse(jsonData.results);
    setTableData3(parsedData);
  })
  .catch((error) => console.error('Error fetching data for Table 3: ', error));


// Fetch data for Table 4
fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/superchats?option=180_day_top_donor_by_channel&channel_nickname=ac3&username=${user}`)
  .then((response) => response.json())
  .then((jsonData) => {
    const parsedData = JSON.parse(jsonData.results);
    setTableData4(parsedData);
  })
  .catch((error) => console.error('Error fetching data for Table 4: ', error));

// Fetch data for Table 5
fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/superchats?option=180_day_top_donor_by_channel&channel_nickname=wn&username=${user}`)
  .then((response) => response.json())
  .then((jsonData) => {
    const parsedData = JSON.parse(jsonData.results);
    setTableData5(parsedData);
  })
  .catch((error) => console.error('Error fetching data for Table 5: ', error));

// Fetch data for Table 6
fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/superchats?option=180_day_top_donor_by_channel&channel_nickname=timmy&username=${user}`)
  .then((response) => response.json())
  .then((jsonData) => {
    const parsedData = JSON.parse(jsonData.results);
    setTableData6(parsedData);
  })
  .catch((error) => console.error('Error fetching data for Table 6: ', error));

// Fetch data for Table 7 (mookie donors)
fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/superchats?option=30_day_top_donor_by_channel&channel_nickname=mookie&username=${user}`)
  .then((response) => response.json())
  .then((jsonData) => {
    const parsedData = JSON.parse(jsonData.results);
    setTableData7(parsedData);
  })
  .catch((error) => console.error('Error fetching data for Table 7: ', error));

// Fetch data for Table 8
fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/superchats?option=180_day_top_donor_by_channel&channel_nickname=mookie&username=${user}`)
  .then((response) => response.json())
  .then((jsonData) => {
    const parsedData = JSON.parse(jsonData.results);
    setTableData8(parsedData);
  })
  .catch((error) => console.error('Error fetching data for Table 8: ', error));


}, []);

    
  const past7DaysData = lineData.slice(lineData.length - 7);
  const past30DaysData = lineData.slice(lineData.length - 30);

  const summedValues7 = {
    wn: 0,
    ac3: 0,
    dungeon: 0,
    dis: 0,
    mookie: 0,
    jj: 0,
    timmy: 0,
    community: 0,
    ocean: 0,
  };

const summedValues30 = {
  wn: 0,
  ac3: 0,
  dungeon: 0,
  dis: 0,
  mookie: 0,
  jj: 0,
  timmy: 0,
  community: 0,
  ocean: 0,
};

const currentDate = new Date();
const yesterdayDate = new Date();
yesterdayDate.setDate(currentDate.getDate() - 1);

const currentDateFormatted = currentDate.toLocaleDateString('en-CA');
const yesterdayDateFormatted = yesterdayDate.toLocaleDateString('en-CA');

const todayData = lineData.find((item) => item.date === currentDateFormatted);
const yesterdayData = lineData.find((item) => item.date === yesterdayDateFormatted);

past30DaysData.forEach((dayData) => {
  summedValues30.wn += dayData.wn;
  summedValues30.ac3 += dayData.ac3;
  summedValues30.dungeon += dayData.dungeon;
  summedValues30.dis += dayData.dis;
  summedValues30.mookie += dayData.mookie;
  summedValues30.jj += dayData.jj;
  summedValues30.timmy += dayData.timmy;
  summedValues30.community += dayData.community;
  summedValues30.ocean += dayData.ocean;
});


 past7DaysData.forEach((dayData) => {
  summedValues7.wn += dayData.wn;
  summedValues7.ac3 += dayData.ac3;
  summedValues7.dungeon += dayData.dungeon;
  summedValues7.dis += dayData.dis;
  summedValues7.mookie += dayData.mookie;
  summedValues7.jj += dayData.jj;
  summedValues7.timmy += dayData.timmy;
  summedValues7.community += dayData.community;
  summedValues7.ocean += dayData.ocean;
});


 const barChartData30 = [
  {
    date: 'Last 30 Days',
    wn: summedValues30.wn,
    ac3: summedValues30.ac3,
    dungeon: summedValues30.dungeon,
    dis: summedValues30.dis,
    mookie: summedValues30.mookie,
    jj: summedValues30.jj,
    timmy: summedValues30.timmy,
    community: summedValues30.community,
    ocean: summedValues30.ocean,
  },
];

 const barChartData7 = [
  {
    date: 'Last 7 Days',
    wn: summedValues7.wn,
    ac3: summedValues7.ac3,
    dungeon: summedValues7.dungeon,
    dis: summedValues7.dis,
    mookie: summedValues7.mookie,
    jj: summedValues7.jj,
    timmy: summedValues7.timmy,
    community: summedValues7.community,
    ocean: summedValues7.ocean,
  },
];
const barChartDataToday = [
  {
    date: 'Today',
    wn: todayData && todayData.wn ? todayData.wn : 0,
    ac3: todayData && todayData.ac3 ? todayData.ac3 : 0,
    dungeon: todayData && todayData.dungeon ? todayData.dungeon : 0,
    dis: todayData && todayData.dis ? todayData.dis : 0,
    mookie: todayData && todayData.mookie ? todayData.mookie : 0,
    jj: todayData && todayData.jj ? todayData.jj : 0,
    timmy: todayData && todayData.timmy ? todayData.timmy : 0,
    community: todayData && todayData.community ? todayData.community : 0,
    ocean: todayData && todayData.ocean ? todayData.ocean : 0,
  },
];

const barChartDataYesterday = [
  {
    date: 'Yesterday',
    wn: yesterdayData && yesterdayData.wn ? yesterdayData.wn : 0,
    ac3: yesterdayData && yesterdayData.ac3 ? yesterdayData.ac3 : 0,
    dungeon: yesterdayData && yesterdayData.dungeon ? yesterdayData.dungeon : 0,
    dis: yesterdayData && yesterdayData.dis ? yesterdayData.dis : 0,
    mookie: yesterdayData && yesterdayData.mookie ? yesterdayData.mookie : 0,
    jj: yesterdayData && yesterdayData.jj ? yesterdayData.jj : 0,
    timmy: yesterdayData && yesterdayData.timmy ? yesterdayData.timmy : 0,
    community: yesterdayData && yesterdayData.community ? yesterdayData.community : 0,
    ocean: yesterdayData && yesterdayData.ocean ? yesterdayData.ocean : 0,
  },
];



  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginBottom: '10px', textAlign: 'center' }}>
        <br /><h5>24 Hour (Today / Yesterday)</h5></div>

          <Typography variant="h6" align="center" gutterBottom></Typography>

          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '50%', paddingLeft: '4%', paddingRight: '1%', paddingTop: '2%' }}>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart data={barChartDataToday}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="wn" fill="#052afc">
                      <LabelList dataKey="wn" position="inside" />
                    </Bar>
                    <Bar dataKey="ac3" fill="#309906">
                      <LabelList dataKey="ac3" position="inside" />
                    </Bar>
                    <Bar dataKey="dungeon" fill="#145240">
                      <LabelList dataKey="dungeon" position="inside" />
                    </Bar>
                    <Bar dataKey="mookie" fill="#008585">
                      <LabelList dataKey="mookie" position="inside" />
                    </Bar>
                    <Bar dataKey="jj" fill="#547a78">
                      <LabelList dataKey="jj" position="inside" />
                    </Bar>          
                    <Bar dataKey="timmy" fill="#ba1493">
                      <LabelList dataKey="timmy" position="inside" />
                    </Bar>
                    <Bar dataKey="community" fill="#8608a8">
                      <LabelList dataKey="community" position="inside" />
                    </Bar>
                    <Bar dataKey="ocean" fill="#780611">
                      <LabelList dataKey="ocean" position="inside" />
                    </Bar>         
                    <Legend />
                </BarChart>
              </ResponsiveContainer>
            </div>

          <div style={{ width: '50%', paddingRight: '4%', paddingLeft: '1%', paddingTop: '2%' }}>
              <ResponsiveContainer width="100%" height={250}>
                 <BarChart
                    data={barChartDataYesterday}
                    
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                  <Bar dataKey="wn" fill="#052afc">
                    <LabelList dataKey="wn" position="inside" />
                  </Bar>
                  <Bar dataKey="ac3" fill="#309906">
                    <LabelList dataKey="ac3" position="inside" />
                  </Bar>
                  <Bar dataKey="dungeon" fill="#145240">
                    <LabelList dataKey="dungeon" position="inside" />
                  </Bar>
                  <Bar dataKey="mookie" fill="#008585">
                    <LabelList dataKey="mookie" position="inside" />
                  </Bar>
                  <Bar dataKey="jj" fill="#547a78">
                    <LabelList dataKey="jj" position="inside" />
                  </Bar>          
                  <Bar dataKey="timmy" fill="#ba1493">
                    <LabelList dataKey="timmy" position="inside" />
                  </Bar>
                  <Bar dataKey="community" fill="#8608a8">
                    <LabelList dataKey="community" position="inside" />
                  </Bar>
                  <Bar dataKey="ocean" fill="#780611">
                    <LabelList dataKey="ocean" position="inside" />
                  </Bar>       
                    <Legend />
                </BarChart>
              </ResponsiveContainer>
          </div>
          </div>

      <br />
      <Typography variant="h6" align="center" gutterBottom>
        <br />7 DAY SUMMARY<br />
      </Typography>

      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '50%', paddingLeft: '4%', paddingRight: '1%', paddingTop: '2%' }}>
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart
          data={lineData.slice(-8,-1).map(item => ({
            ...item,
            date: DateTime.fromISO(item.date, { zone: 'America/Los_Angeles' }).toMillis(),
          }))}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="date" 
          scale="time" 
          type="number" 
          domain={['auto', 'auto']} 
          tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
        />
        <YAxis />
        <Tooltip labelFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()} />
          <Area type="monotone" dataKey="wn" stackId="1" stroke="#052afc" fill="#052afc" />
            <Area type="monotone" dataKey="ac3" stackId="1" stroke="#309906" fill="#309906" />
            <Area type="monotone" dataKey="dungeon" stackId="1" stroke="#145240" fill="#145240" />
            <Area type="monotone" dataKey="mookie" stackId="1" stroke="#008585" fill="#008585" />
            <Area type="monotone" dataKey="jj" stackId="1" stroke="#547a78" fill="#547a78" />
            <Area type="monotone" dataKey="timmy" stackId="1" stroke="#ba1493" fill="#ba1493" />
            <Area type="monotone" dataKey="community" stackId="1" stroke="#8608a8" fill="#8608a8" />
            <Area type="monotone" dataKey="ocean" stackId="1" stroke="#780611" fill="#780611" />
        <Legend />
      </AreaChart>
    </ResponsiveContainer>
    </div>


<div style={{ width: '50%', paddingRight: '4%', paddingLeft: '1%', paddingTop: '2%' }}>
  <ResponsiveContainer width="100%" height={250}>
 <BarChart
  data={barChartData7}
  
  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
  >
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis dataKey="date" />
  <YAxis />
  <Tooltip />
          <Bar dataKey="wn" fill="#052afc">
            <LabelList dataKey="wn" position="inside" />
          </Bar>
          <Bar dataKey="ac3" fill="#309906">
            <LabelList dataKey="ac3" position="inside" />
          </Bar>
          <Bar dataKey="dungeon" fill="#145240">
            <LabelList dataKey="dungeon" position="inside" />
          </Bar>
          <Bar dataKey="mookie" fill="#008585">
            <LabelList dataKey="mookie" position="inside" />
          </Bar>
          <Bar dataKey="jj" fill="#547a78">
            <LabelList dataKey="jj" position="inside" />
          </Bar>          
          <Bar dataKey="timmy" fill="#ba1493">
            <LabelList dataKey="timmy" position="inside" />
          </Bar>
          <Bar dataKey="community" fill="#8608a8">
            <LabelList dataKey="community" position="inside" />
          </Bar>
          <Bar dataKey="ocean" fill="#780611">
            <LabelList dataKey="ocean" position="inside" />
          </Bar>       
  <Legend />
</BarChart>
  </ResponsiveContainer>
  </div>

</div>

      <Typography variant="h6" align="center" gutterBottom>
        <br /><br />30 DAY SUMMARY
      </Typography>

      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '50%', paddingLeft: '4%', paddingRight: '1%', paddingTop: '2%' }}>

      <ResponsiveContainer width="100%" height={250}>
        <AreaChart
        data={past30DaysData
          .filter(item => {
        const itemDate = DateTime.fromISO(item.date, { zone: 'America/Los_Angeles' });
        const yesterday = DateTime.local().minus({ days: 1 }).setZone('America/Los_Angeles');
        return itemDate < yesterday;
    })
    .map(item => ({
      ...item,
      date: DateTime.fromISO(item.date, { zone: 'America/Los_Angeles' }).toMillis(),
    }))}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="date" 
              scale="time" 
              type="number" 
              domain={['auto', 'auto']} 
              tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
            />
            <YAxis />
            <Tooltip labelFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()} />
            <Area type="monotone" dataKey="wn" stackId="1" stroke="#052afc" fill="#052afc" />
            <Area type="monotone" dataKey="ac3" stackId="1" stroke="#309906" fill="#309906" />
            <Area type="monotone" dataKey="dungeon" stackId="1" stroke="#145240" fill="#145240" />
            <Area type="monotone" dataKey="mookie" stackId="1" stroke="#008585" fill="#008585" />
            <Area type="monotone" dataKey="jj" stackId="1" stroke="#547a78" fill="#547a78" />
            <Area type="monotone" dataKey="timmy" stackId="1" stroke="#ba1493" fill="#ba1493" />
            <Area type="monotone" dataKey="community" stackId="1" stroke="#8608a8" fill="#8608a8" />
            <Area type="monotone" dataKey="ocean" stackId="1" stroke="#780611" fill="#780611" />
            <Legend />
          </AreaChart>
        </ResponsiveContainer>
        </div>

        <div style={{ width: '50%', paddingRight: '4%', paddingLeft: '1%', paddingTop: '2%' }}>
          <ResponsiveContainer width="100%" height={250}>
            <BarChart
              data={barChartData30}
              
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
          <Bar dataKey="wn" fill="#052afc">
            <LabelList dataKey="wn" position="inside" />
          </Bar>
          <Bar dataKey="ac3" fill="#309906">
            <LabelList dataKey="ac3" position="inside" />
          </Bar>
          <Bar dataKey="dungeon" fill="#145240">
            <LabelList dataKey="dungeon" position="inside" />
          </Bar>
          <Bar dataKey="mookie" fill="#008585">
            <LabelList dataKey="mookie" position="inside" />
          </Bar>
          <Bar dataKey="jj" fill="#547a78">
            <LabelList dataKey="jj" position="inside" />
          </Bar>          
          <Bar dataKey="timmy" fill="#ba1493">
            <LabelList dataKey="timmy" position="inside" />
          </Bar>
          <Bar dataKey="community" fill="#8608a8">
            <LabelList dataKey="community" position="inside" />
          </Bar>
          <Bar dataKey="ocean" fill="#780611">
            <LabelList dataKey="ocean" position="inside" />
          </Bar>      
             <Legend />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <br /><br />
      <Typography variant="h6" align="center" gutterBottom>
        <br />30 DAY (TOP 25)
        <br />
      </Typography>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '500px' }}>
          <div style={{ marginBottom: '10px', textAlign: 'center' }}>AC3</div>
          <DataTable data={tableData1} />
        </div>

        <div style={{ width: '500px' }}>
          <div style={{ marginBottom: '10px', textAlign: 'center' }}>WN</div>
          <DataTable data={tableData2} />
        </div>

        <div style={{ width: '500px' }}>
          <div style={{ marginBottom: '10px', textAlign: 'center' }}>TIMMY</div>
          <DataTable data={tableData3} />
        </div>

        <div style={{ width: '500px' }}>
          <div style={{ marginBottom: '10px', textAlign: 'center' }}>MOOKIE</div>
          <DataTable data={tableData7} />
        </div>

      </div>

      <Typography variant="h6" align="center" gutterBottom>
        <br /><br />6 MONTH (TOP 25)
      </Typography>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '500px' }}>
          <div style={{ marginBottom: '10px', textAlign: 'center' }}>AC3</div>
          <DataTable data={tableData4} />
        </div>

        <div style={{ width: '500px' }}>
          <div style={{ marginBottom: '10px', textAlign: 'center' }}>WN</div>
          <DataTable data={tableData5} />
        </div>

        <div style={{ width: '500px' }}>
          <div style={{ marginBottom: '10px', textAlign: 'center' }}>TIMMY</div>
          <DataTable data={tableData6} />
        </div>

        <div style={{ width: '500px' }}>
          <div style={{ marginBottom: '10px', textAlign: 'center' }}>MOOKIE</div>
          <DataTable data={tableData8} />
        </div>


      </div>

    </div>

  );


};




export default Revenue;


