import React from 'react';
import { Line } from 'react-chartjs-2';
import Modal from "react-modal";
import 'chart.js/auto';
import './css/tables.css';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRobotAstromech  } from '@fortawesome/pro-solid-svg-icons'
import { faOctagonXmark } from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownUpAcrossLine } from '@fortawesome/pro-solid-svg-icons';
import { faComment } from '@fortawesome/pro-solid-svg-icons';
import { faChartLine } from '@fortawesome/pro-solid-svg-icons';
import { faHardDrive } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons'; // for duotone
import { faCircleQuarterStroke } from '@fortawesome/pro-duotone-svg-icons'; // for duotone 25% circle
import { faCircleHalfStroke } from '@fortawesome/pro-duotone-svg-icons'; // for duotone 50% circle
import { faCircleThreeQuartersStroke } from '@fortawesome/pro-duotone-svg-icons'; // for duotone 75% circle

const ViewerChartModal = ({ viewerData, isChartModalOpen, closeChartModal }) => {
  // Check if viewerData is empty or undefined before mapping
  if (!viewerData || viewerData.length === 0) {
    return null; // Return null or any other message when viewerData is empty
  }

  const data = {
    labels: viewerData.map(item => item.timestamp),
    datasets: [
      {
        label: 'concurrent_viewers',
        data: viewerData.map(item => item.concurrenct_viewers),
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(39, 141, 245, 0.8)',
        pointRadius: 5, // Set point radius to make data points easier to interact with
        pointHoverRadius: 7, // Set point hover radius for highlighting effect
      },
      {
        label: 'superchat_value_usd',
        data: viewerData.map(item => item.total_superchat_usd),
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
        pointRadius: 5, // Set point radius to make data points easier to interact with
        pointHoverRadius: 7, // Set point hover radius for highlighting effect
      },
      {
        label: 'chats_per_minute_rate',
        data: viewerData.map(item => item.chats_per_min),
        fill: false,
        backgroundColor: 'rgba(124, 17, 173, 0.8)',
        borderColor: 'rgba(124, 17, 173, 0.8)',
        yAxisID: 'y1',
        pointRadius: 5, // Set point radius to make data points easier to interact with
        pointHoverRadius: 7, // Set point hover radius for highlighting effect
      },
    ],
  };

  const options = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Concurrent Viewers & Superchat Value (USD)'
      }
    },
    y1: { // Define a new y-axis for chats_per_minute_rate
      position: 'right', // Position it on the right
      beginAtZero: true,
      max: 20, // Set the maximum value
      grid: {
        drawOnChartArea: false, // Ensure grid lines don't overlap with the left y-axis
      },
      title: {
        display: true,
        text: 'Chats per Minute Rate'
      }
    },
    x: {
      // Additional configuration may go here
    }
  },
  plugins: {
    legend: {
      display: true,
    },
tooltip: {
  enabled: true,
  mode: 'index',
callbacks: {
  afterBody: (tooltipItems, data) => {
    const dataIndex = tooltipItems[0].dataIndex;

    // Retrieve data for this index
    const chatData = viewerData[dataIndex].aggregated_chat_data;
    const hashtagsData = viewerData[dataIndex].transcript_summarization_hashtags;
    const summaryData = viewerData[dataIndex].transcript_summarization_summary;
    const topicData = viewerData[dataIndex].transcript_summarization_major_topics;

    // Initialize arrays to store the data
    let chatMessages = [];
    let hashtags = '';
    let summary = '';
    let topics = '';

    // Format the chat data into a readable string
    if (chatData && chatData.length > 0) {
      chatMessages = chatData.map(chat => `superchats: ${chat.author_name} ($${chat.dollar_value_usd}): ${chat.message}`);
    }

    // Process hashtags data
    if (hashtagsData) {
      const hashtagsArray = JSON.parse(hashtagsData.replace(/\\/g, ''));
      hashtags = 'hashtags: ' + hashtagsArray.join(', ');
    }

    // Process summary data
    if (summaryData) {
      summary = 'summary: ' + summaryData;
    }

    // Process topic data
    if (topicData) {
      const topicArray = JSON.parse(topicData.replace(/\\/g, ''));
      topics = 'major_topics: ' + topicArray.join(', ');
    }

    // Combine chat messages, hashtags, topics, and summary
    return [...chatMessages, hashtags, topics, summary].join('\n').trim();
  }
}

},
  },
};

  return (
    <Modal 
      isOpen={isChartModalOpen} 
      onRequestClose={closeChartModal}
      appElement={document.getElementById('root')}
    >
      <div className="modal-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h6>live stats</h6>
        
        <FontAwesomeIcon
          icon={faOctagonXmark}
          className="fa-thin"
          onClick={closeChartModal}
          style={{ color: 'red', cursor: 'pointer' }}
        />
      </div>
      {/* Temporary div to check modal content area */}
      {/* Check if viewerData is empty or undefined before rendering the chart */}
      {viewerData && viewerData.length > 0 ? (
        <Line data={data} options={options} />
      ) : (
        <p>No viewer data available.</p>
      )}
    </Modal>
  );
};

export default ViewerChartModal;
