
import React, { useState, useEffect, useMemo } from 'react';
import { useTable } from 'react-table';
import Modal from "react-modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faScrollOld, faArrowsSpin, faComment, faTrash,
  faChartLine, faDownload, faHardDrive, faCircle,
  faCircleQuarterStroke, faCircleHalfStroke,
  faCircleThreeQuartersStroke, faBulldozer, faMessagesDollar, faFlagCheckered
} from '@fortawesome/pro-solid-svg-icons';


export const DataTable = ({ data, columns, expandedRowId, onRowClick }) => {
  const [isThumbnailModalOpen, setIsThumbnailModalOpen] = useState(false);
  const [thumbnailUrlHQ, setThumbnailUrlHQ] = useState('');

  const handleThumbnailClick = (url, urlHQ) => {
    setThumbnailUrlHQ(urlHQ);
    setIsThumbnailModalOpen(true);
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    borderColor: '1px solid #222f4a'
  };

  const thStyle = {
    backgroundColor: '#213454',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '8px',
    borderBottom: '1px solid #222f4a',
  };

  const tdStyle = {
    padding: '8px',
    borderBottom: '1px solid #2c3038',
    backgroundColor: (row) => (row.original.is_active ? '#8aff80' : 'inherit'),
    borderLeft: '1px solid #222f4a',
    borderRight: '1px solid #222f4a',
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <div className="my-table-container" style={{ maxHeight: "600px", overflowY: "auto" }}>
      <table {...getTableProps()} style={tableStyle}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} style={thStyle}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  let cellStyle = { ...tdStyle, ...cell.column.style };

                  if (cell.column.id === 'superchat_usd' ||
                    (cell.column.id === 'revenue' && row.original.is_active === true)) {
                    cellStyle = {
                      ...cellStyle,
                      backgroundColor: cell.value <= 10
                        ? 'red'
                        : cell.value <= 50
                          ? 'yellow'
                          : 'green'
                    };
                  } else if (cell.column.id === 'revenue' && row.original.is_active === false) {
                  } else {
                  }

                  if (cell.column.id === 'video_thumbnail') {
                    return (
                      <td {...cell.getCellProps()} style={cellStyle}>
                        <div onClick={() => handleThumbnailClick(cell.value, row.original.video_thumbnail_hq)}>
                          <img src={cell.value} alt="Thumbnail" style={{ width: '100px', cursor: 'pointer' }} />
                        </div>
                      </td>
                    );
                  } else {
                    return (
                      <td {...cell.getCellProps()} style={cellStyle}>
                        {cell.render('Cell')}
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal
        isOpen={isThumbnailModalOpen}
        onRequestClose={() => setIsThumbnailModalOpen(false)}
        style={{
          content: {
            position: 'absolute',
            top: '55%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: 'opaque',
            padding: 0,
            border: 'none',
            overflow: 'visible'
          },
          overlay: {
            backgroundColor: 'black'
          }
        }}
        overlayClassName={"ReactModal__Overlay"}
        contentLabel="Expanded Thumbnail"
      >
        <div onClick={() => setIsThumbnailModalOpen(false)} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={thumbnailUrlHQ} alt="Expanded Thumbnail" style={{ width: '65vw', height: 'auto', maxWidth: '95vw', maxHeight: '95vh' }} />
        </div>
      </Modal>
    </div>
  );
};

export default DataTable;
