import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScissors } from '@fortawesome/pro-duotone-svg-icons';
import { faScrollOld, faDownload } from '@fortawesome/pro-solid-svg-icons';
import * as utils from '../utils';
import { TABLE_COLUMNS } from '../constants';

export const SearchResultsTable = ({
  currentResults,
  handleRowClick,
  handleRowMouseEnter,
  handleRowMouseLeave,
  selectedRow,
  handleClipButtonClick,
  handleTranscriptGeneration,
  handleArchivalRequest,
  searchText,
  filterText,
  setVideoUrl,
  setSelectedResult,
  setClipModalOpen,
  setRequestNote,
  setResponseMessage,
  setResponseStatus,
  setArchivalModalOpen,
  defaultRequestNote
}) => (
  <div className="my-table-container" style={{maxHeight: "600px", overflowY: "auto"}}>
    <table className="data">
      <thead>
        <tr>
          {TABLE_COLUMNS.map(column => (
            <th key={column.id} style={{ width: column.width }}>{column.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {currentResults.map((result, index) => (
          <tr
            key={index}
            className={selectedRow === index ? 'highlighted-row' : ''}
            onClick={(event) => handleRowClick(result, event, setVideoUrl)}
            onMouseEnter={() => handleRowMouseEnter(index)}
            onMouseLeave={handleRowMouseLeave}
          >
            <td>{result.channel_nickname}</td>
            <td>{result.video_id}</td>
            <td><img src={result.video_thumbnail_link} alt="Thumbnail" /></td>
            <td>{result.video_timestamp_pst}</td>
            <td>{result.video_title}</td>
            <td>{utils.formatTimestamp(result.video_id, result.start_time)}</td>
            <td>{utils.applyHighlights(result.highlight, searchText, filterText)}</td>
            <td>
              <a href={`https://youtu.be/${result.video_id}?t=${result.start_time}`} target="_blank" rel="noopener noreferrer">
                youtube
              </a>
            </td>
            <td>
              <div className="transcript-button">
                <FontAwesomeIcon 
                  icon={faScissors}
                  className="fa-duotone" 
                  title="generate-clip"
                  onClick={(event) => handleClipButtonClick(event, result, setSelectedResult, setClipModalOpen)}
                  style={{ color: 'blue', display: 'inline-block', marginLeft: '10px' }}
                />
              </div>
              <div className="transcript-button">
                <FontAwesomeIcon 
                  icon={faScrollOld}
                  className="fa-duotone" 
                  title="request-transcript"
                  onClick={(event) => handleTranscriptGeneration(event, result)}
                  style={{ color: 'blue', display: 'inline-block', marginLeft: '10px' }}
                />
              </div>
              <div className="transcript-button">
                <FontAwesomeIcon 
                  icon={faDownload}
                  className="fa-duotone" 
                  title="request-archival-download"
                  onClick={(event) => handleArchivalRequest(event, result, setSelectedResult, setRequestNote, setResponseMessage, setResponseStatus, setArchivalModalOpen, defaultRequestNote)}
                  style={{ color: 'blue', display: 'inline-block', marginLeft: '10px' }}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);