import React, { useState, useContext, useEffect } from 'react'; // <-- Add useEffect
import { useNavigate } from 'react-router-dom';
import { AuthContext, AuthProvider } from './AuthContext'; 

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login, user } = useContext(AuthContext);

  useEffect(() => {
    if (!isLoading && user) {
      navigate('/Superchat');
    }
  }, [isLoading, user, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await login(username, password);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);

  };

  return (
    <form onSubmit={handleLogin}>
      <input
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <button type="submit" disabled={isLoading}>
        {isLoading ? "Logging in..." : "Login"}
      </button>
    </form>
  );
};
export default LoginForm;
