import React, { useState, useEffect, useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { AuthContext } from '../../AuthContext';
import DonorSummary from '../revenue/routeDonorSummary';
import ErrorBoundary from './errorBoundary.js';
import '../../css/tables.css';

Chart.register(...registerables);

const timeRanges = {
  '3 month': 3,
  '6 month': 6,
  '1 year': 12,
  'All': Infinity
};

const UserLookup = () => {
  const { user } = useContext(AuthContext);
  const [userToLookup, setUserToLookup] = useState('');
  const [selectedTimeRange, setSelectedTimeRange] = useState('3 month');
  const [fullData, setFullData] = useState([]);
  const [chartData, setChartData] = useState({ datasets: [] });
  const [tableRows, setTableRows] = useState([]);
  const [totals, setTotals] = useState({ totalChats: 0, sumSuperchats: 0 });
  const [channelNicknames, setChannelNicknames] = useState([]);
  const [selectedChannelNicknames, setSelectedChannelNicknames] = useState(new Set());
  const [authorAliases, setAuthorAliases] = useState([]);
  const [allDistinctAuthorIds, setAllDistinctAuthorIds] = useState([]);
  const [selectedAuthorIds, setSelectedAuthorIds] = useState(new Set());
  const [authorId, setAuthorId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDonorSummary, setShowDonorSummary] = useState(true);
  const [filteredUsernames, setFilteredUsernames] = useState([]);
  const [users, setUsers] = useState([]);

  const [chartOptions] = useState({
    scales: {
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: 'Superchat Revenue'
        },
        ticks: {
          callback: value => `$${value.toFixed(2)}`
        }
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: context => `$${context.raw.y.toFixed(2)}`
        }
      }
    }
  });

  // Load usernames from localStorage on component mount
  useEffect(() => {
    const storedUsernames = localStorage.getItem('superchat-usernames');
    if (storedUsernames) {
      try {
        const parsed = JSON.parse(storedUsernames);
        const usernames = Array.isArray(parsed.results) 
          ? parsed.results.map(user => user.author_name) // Map to only author_name
          : [];
        setUsers(usernames);
      } catch (error) {
        console.error('Error parsing users from localStorage:', error);
        setUsers([]);
      }
    }
  }, []);

function getLocalStorageUsage() {
  let totalBytes = 0;

  for (const key in localStorage) {
    if (localStorage.hasOwnProperty(key)) {
      const item = localStorage.getItem(key);
      const blob = new Blob([item]);
      totalBytes += blob.size;
    }
  }

  console.log(`Total localStorage usage: ${totalBytes} bytes`);
  return totalBytes;
}

const usedBytes = getLocalStorageUsage();
const maxStorage = 5 * 1024 * 1024; // 5 MB limit (adjust based on your assumptions)
const remainingBytes = maxStorage - usedBytes;
console.log(`Remaining localStorage space: ${remainingBytes} bytes`);

  // Initialize channel nicknames
  useEffect(() => {
    const defaultChannelNicknames = new Set(["ac3", "wn", "timmy", "dungeon", "wn_live"]);
    const allChannelNicknames = new Set(fullData.map(item => item.channel_nickname));
    setChannelNicknames([...allChannelNicknames]);
    
    const selectedNicknames = new Set(
      [...allChannelNicknames].filter(nickname => defaultChannelNicknames.has(nickname))
    );
    setSelectedChannelNicknames(selectedNicknames);
  }, [fullData]);

  const filterDataByTimeRange = (data, timeRange) => {
    if (!Array.isArray(data)) {
      console.error('Invalid data type for filtering:', data);
      return [];
    }

    if (timeRange === 'All') {
      return data;
    }

    const now = new Date();
    const monthsToInclude = timeRanges[timeRange];
    const cutoffDate = new Date(now.setMonth(now.getMonth() - monthsToInclude));

    console.log('Time range filtering:', {
      timeRange,
      monthsToInclude,
      cutoffDate,
      dataLength: data.length
    });

    return data.filter(item => {
      const itemDate = new Date(item.date);
      return itemDate >= cutoffDate;
    });
  };

  const aggregateDataForTable = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    console.warn('No data to aggregate for table');
    return [];
  }

  console.log('Starting table aggregation with data length:', data.length);

  const monthlyTotals = new Map();

  data.forEach(item => {
    if (!item.date) {
      console.warn('Item missing date:', item);
      return;
    }

    const date = new Date(item.date);
    const month = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
    
    const monthData = monthlyTotals.get(month) || { total_chats: 0, sum_superchats: 0 };
    const chats = Number(item.total_chats) || 0;
    const superchats = Number(item.sum_superchats) || 0;
    
    monthData.total_chats += chats;
    monthData.sum_superchats += superchats;
    
    console.log(`Adding to month ${month}:`, {
      authorId: item.author_id,
      chats,
      superchats,
      newTotal: monthData.sum_superchats
    });
    
    monthlyTotals.set(month, monthData);
  });

  const result = Array.from(monthlyTotals.entries())
    .map(([month, values]) => ({
      month,
      totalChats: values.total_chats,
      sumSuperchats: Number(values.sum_superchats.toFixed(2))
    }))
    .sort((a, b) => new Date(a.month) - new Date(b.month));

  console.log('Final table aggregation result:', result);
  return result;
};

const aggregateData = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    console.warn('No data to aggregate for chart');
    return [];
  }

  console.log('Starting chart aggregation with data length:', data.length);

  const dateMap = new Map();

  data.forEach(item => {
    if (!item.date) {
      console.warn('Item missing date:', item);
      return;
    }

    const dateStr = new Date(item.date).toISOString().split('T')[0];
    const dateData = dateMap.get(dateStr) || { total_chats: 0, sum_superchats: 0 };
    
    const chats = Number(item.total_chats) || 0;
    const superchats = Number(item.sum_superchats) || 0;
    
    dateData.total_chats += chats;
    dateData.sum_superchats += superchats;
    
    console.log(`Adding to date ${dateStr}:`, {
      authorId: item.author_id,
      chats,
      superchats,
      newTotal: dateData.sum_superchats
    });
    
    dateMap.set(dateStr, dateData);
  });

  const sortedDates = Array.from(dateMap.keys()).sort();
  const result = [{
    dates: sortedDates,
    totalChats: sortedDates.map(date => dateMap.get(date).total_chats),
    sumSuperchats: sortedDates.map(date => Number(dateMap.get(date).sum_superchats.toFixed(2)))
  }];

  console.log('Final chart aggregation result:', result);
  return result;
};

 useEffect(() => {
  if (!Array.isArray(fullData) || fullData.length === 0) {
    console.log('No full data available');
    return;
  }

  console.log('Initial data processing:', {
    totalRecords: fullData.length,
    uniqueAuthorIds: new Set(fullData.map(item => item.author_id)).size,
    selectedAuthorIds: selectedAuthorIds.size,
    selectedChannels: selectedChannelNicknames.size,
    selectedAuthorIdsList: Array.from(selectedAuthorIds)
  });

  // Step 1: Filter by author IDs
  const dataFilteredByAuthor = fullData.filter(item => {
    const isSelected = selectedAuthorIds.has(item.author_id);
    if (isSelected) {
      console.log(`Including data for author_id: ${item.author_id}`, {
        date: item.date,
        chats: item.total_chats,
        superchats: item.sum_superchats
      });
    }
    return isSelected;
  });
  console.log('After author filtering:', dataFilteredByAuthor.length);

    // Step 2: Filter by channels
    const dataFilteredByChannel = dataFilteredByAuthor.filter(item =>
      selectedChannelNicknames.has(item.channel_nickname)
    );
    console.log('After channel filtering:', dataFilteredByChannel.length);

    // Step 3: Filter by time range
    const dataFilteredByTime = filterDataByTimeRange(dataFilteredByChannel, selectedTimeRange);
    console.log('After time filtering:', dataFilteredByTime.length);

    // Process filtered data
    const aggregatedTableData = aggregateDataForTable(dataFilteredByTime);
    setTableRows(aggregatedTableData);

    // Calculate totals
    const totalChatsSum = dataFilteredByTime.reduce((acc, row) => 
      acc + Number(row.total_chats), 0);
    const averageChats = dataFilteredByTime.length > 0 ? 
      Math.round(totalChatsSum / dataFilteredByTime.length) : 0;
    const totalSuperchats = dataFilteredByTime.reduce((acc, row) => 
      acc + Number(row.sum_superchats), 0);

    setTotals({
      totalChats: averageChats,
      sumSuperchats: Number(totalSuperchats.toFixed(2))
    });

    // Update chart
    const chartData = aggregateData(dataFilteredByTime);
    if (chartData.length > 0) {
      setChartData({
        labels: chartData[0].dates,
        datasets: [{
          label: 'Total Superchat Revenue',
          data: chartData[0].dates.map((date, index) => ({
            x: date,
            y: chartData[0].sumSuperchats[index]
          })),
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          yAxisID: 'y1',
        }],
      });
    }
  }, [fullData, selectedTimeRange, selectedChannelNicknames, selectedAuthorIds]);

  const fetchUserData = async (username) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/chat_author_summary?` +
        `stype=wide&author=${encodeURIComponent(username)}&clientip=127.0.0.1&username=${encodeURIComponent(user)}`
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const jsonData = await response.json();
      const chatSummaryArray = jsonData.chat_summary || [];
      const authorAliasesArray = jsonData.author_aliases || [];

      console.log('Fetched JSON data as-is:',jsonData);

      console.log('Fetched data:', {
        chatSummaryLength: chatSummaryArray.length,
        authorAliasesLength: authorAliasesArray.length
      });

      setFullData(chatSummaryArray);

      // Extract and set unique author IDs
      const distinctAuthorIds = new Set(chatSummaryArray.map(item => item.author_id));
      const distinctAuthorIdsArray = Array.from(distinctAuthorIds);
      
      //console.log('Author IDs found:', distinctAuthorIdsArray);
      
      setAllDistinctAuthorIds(distinctAuthorIdsArray);
      setSelectedAuthorIds(distinctAuthorIds); // Select all by default
      setAuthorAliases(authorAliasesArray);

      if (chatSummaryArray.length > 0 && chatSummaryArray[0].author_id) {
        setAuthorId(chatSummaryArray[0].author_id);
        setShowDonorSummary(true);
      } else {
        setShowDonorSummary(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setShowDonorSummary(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setUserToLookup(value);

    if (!Array.isArray(users)) {
      console.error('Users data is not an array:', users);
      setFilteredUsernames([]);
      return;
    }

    if (!value.trim()) {
      setFilteredUsernames([]);
      return;
    }

    const filtered = users
      .filter(username => {
        if (typeof username !== 'string') {
          console.warn('Invalid username entry:', username);
          return false;
        }
        return username.toLowerCase().includes(value.toLowerCase());
      })
      .slice(0, 100);

    setFilteredUsernames(filtered);
  };

  const handleUsernameClick = (username) => {
    if (!username) return;
    setFilteredUsernames([]);
    setTimeout(() => {
      setUserToLookup(username);
      fetchUserData(username);
    }, 0);
  };

  const handleAuthorIdCheckboxChange = (authorId) => {
    setSelectedAuthorIds(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(authorId)) {
        newSelection.delete(authorId);
      } else {
        newSelection.add(authorId);
      }
      return newSelection;
    });
  };

  const handleChannelCheckboxChange = (nickname) => {
    setSelectedChannelNicknames(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(nickname)) {
        newSelection.delete(nickname);
      } else {
        newSelection.add(nickname);
      }
      return newSelection;
    });
  };

  const handleToggleAllChannels = () => {
    setSelectedChannelNicknames(prev => 
      prev.size === channelNicknames.length ? new Set() : new Set(channelNicknames)
    );
  };

  const generateAuthorIdLink = (authorId) => 
    `https://youtube.com/channel/${authorId}`;

  // Styles
  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    borderColor: '1px solid #222f4a'
  };

  const thStyle = {
    backgroundColor: '#213454',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: '8px',
    borderBottom: '1px solid #222f4a',
  };

  const tdStyle = {
    padding: '8px',
    borderBottom: '1px solid #2c3038',
    borderLeft: '1px solid #222f4a',
    borderRight: '1px solid #222f4a',
  };

return (
    <div style={{ width: '100%', height: 'auto', maxWidth: '1200px', margin: 'auto' }}>
      <div style={{ position: 'relative' }}>
        {isLoading && <div className="spinner"></div>}
        
        {/* Search Section */}
        <div style={{ position: 'relative', zIndex: 1000 }}>
          <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: '8px',
            marginBottom: '10px'
          }}>
            <input
              type="text"
              value={userToLookup}
              onChange={handleInputChange}
              placeholder="Enter username"
              style={{
                height: '40px',
                padding: '8px 12px',
                fontSize: '16px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                backgroundColor: '#2b2b2b',
                color: 'white',
                width: '300px'
              }}
            />
            
            <button 
              onClick={() => fetchUserData(userToLookup)}
              style={{
                height: '40px',
                padding: '8px 20px',
                fontSize: '16px',
                backgroundColor: '#666',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                minWidth: '60px'
              }}
            >
              Go
            </button>

            {/* Time Range Buttons */}
            {Object.keys(timeRanges).map((range) => (
              <button
                key={range}
                onClick={() => setSelectedTimeRange(range)}
                style={{
                  height: '40px',
                  padding: '8px 16px',
                  fontSize: '16px',
                  backgroundColor: selectedTimeRange === range ? '#4CAF50' : '#333',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  minWidth: '100px'
                }}
              >
                {range}
              </button>
            ))}
          </div>

          {/* Autocomplete Dropdown */}
          {filteredUsernames.length > 0 && (
            <div style={{
              position: 'absolute',
              top: '45px',
              left: 0,
              width: '300px',
              backgroundColor: '#2b2b2b',
              boxShadow: '0px 4px 5px rgba(0,0,0,0.2)',
              zIndex: 1001,
              borderRadius: '4px',
              marginTop: '5px'
            }}>
              <ul style={{
                listStyleType: 'none',
                padding: 0,
                margin: 0,
                maxHeight: '200px',
                overflowY: 'auto'
              }}>
                {filteredUsernames.map((username, index) => (
                  <li 
                    key={`${username}-${index}`}
                    onClick={() => handleUsernameClick(username)}
                    style={{
                      cursor: 'pointer',
                      padding: '8px 12px',
                      borderBottom: '1px solid #444',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#3b3b3b'
                      }
                    }}
                  >
                    {username}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

{/* Author IDs Section */}
{allDistinctAuthorIds.length > 0 && (
  <div style={{ marginTop: '20px', marginBottom: '20px' }}>
    <h3 style={{ color: '#fff', marginBottom: '10px' }}></h3>
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      {allDistinctAuthorIds.map((authorId) => {
        const alias = authorAliases.find(item => item.author_id === authorId);
        return (
          <li key={authorId} style={{ marginBottom: '8px' }}>
            <label style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <input
                type="checkbox"
                checked={selectedAuthorIds.has(authorId)}
                onChange={() => handleAuthorIdCheckboxChange(authorId)}
              />
              <a
                href={generateAuthorIdLink(authorId)}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#66b3ff' }}
              >
                {authorId}
              </a>
              {alias && (
                <span style={{ color: '#888' }}>
                  - {alias.author_names.map((name, nameIndex) => (
                    <React.Fragment key={nameIndex}>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleUsernameClick(name);
                        }}
                        style={{ 
                          color: '#00f',
                          textDecoration: 'underline',
                          marginRight: '5px',
                          cursor: 'pointer'
                        }}
                      >
                        {name}
                      </a>
                      {nameIndex < alias.author_names.length - 1 && ', '}
                    </React.Fragment>
                  ))}
                </span>
              )}
            </label>
          </li>
        );
      })}
    </ul>
  </div>
)}

        {/* Channel Selection Section */}
        <div style={{ marginBottom: '20px' }}>
          <h3 style={{ color: '#fff', marginBottom: '10px' }}></h3>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <input
                type="checkbox"
                checked={selectedChannelNicknames.size === channelNicknames.length}
                onChange={handleToggleAllChannels}
              />
              All Channels
            </label>

            {channelNicknames.map(nickname => (
              <label key={nickname} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <input
                  type="checkbox"
                  checked={selectedChannelNicknames.has(nickname)}
                  onChange={() => handleChannelCheckboxChange(nickname)}
                />
                {nickname}
              </label>
            ))}
          </div>
        </div>

        {/* Data Visualization Section */}
        <div style={{ display: 'flex', width: '100%', marginTop: '20px' }}>
          {/* Chart */}
          <div style={{ width: '50%', paddingRight: '20px' }}>
            <Line data={chartData} options={chartOptions} />
          </div>

          {/* Table */}
          <div style={{ width: '50%' }}>
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Month</th>
                  <th style={thStyle}>Chats</th>
                  <th style={thStyle}>Revenue</th>
                </tr>
              </thead>
              <tbody>
                {tableRows.map((row, index) => (
                  <tr key={index}>
                    <td style={tdStyle}>{row.month}</td>
                    <td style={tdStyle}>{row.totalChats.toLocaleString()}</td>
                    <td style={tdStyle}>${row.sumSuperchats.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}</td>
                  </tr>
                ))}
                <tr style={{ backgroundColor: '#213454' }}>
                  <td style={tdStyle}>Summary</td>
                  <td style={tdStyle}>{totals.totalChats.toLocaleString()} avg.</td>
                  <td style={tdStyle}>${totals.sumSuperchats.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })} total</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Donor Summary Section */}
        {showDonorSummary && authorId && (
          <div style={{ marginTop: '30px' }}>
            <ErrorBoundary>
              <h3 style={{ color: '#fff', marginBottom: '10px' }}>Donor Activity Summary</h3>
              <DonorSummary donorId={authorId} />
            </ErrorBoundary>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserLookup;


