import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faScrollOld, faArrowsSpin, faComment, faTrash,
  faChartLine, faDownload, faHardDrive, faCircle,
  faCircleQuarterStroke, faCircleHalfStroke,
  faCircleThreeQuartersStroke, faBulldozer, faMessagesDollar, faFlagCheckered
} from '@fortawesome/pro-solid-svg-icons';

export const createActiveAndScheduledColumns = (handleRowClick, handleViewersClick, handleRowTrash, handleRowForceComplete) => [
  {
    id: 'channel_nickname',
    Header: 'channel',
    accessor: 'channel_nickname',
    width: 120,
    maxWidth: 120,
    Cell: ({ value, row }) => (
      <div style={{
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '120px',
      }}>
        <a href={row.original.channel_streams_link} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      </div>
    ),
  },
  {
    id: 'video_id_link',
    Header: 'video_id',
    accessor: 'video_id',
    width: 120,
    maxWidth: 120,
    Cell: ({ value, row }) => (
      <div style={{
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '120px',
      }}>
        <a href={`https://www.youtube.com/watch?v=${row.original.video_id}`} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      </div>
    ),
  },
  {
    Header: 'scheduled_pst',
    accessor: 'stream_scheduled_pst',
    width: 25
  },
  {
    Header: '',
    accessor: 'is_active',
    Cell: ({ value }) => (
      <div style={{ backgroundColor: value ? '#8aff80' : '#ffff8a' }}>
        {value ? 'LIVE' : 'Pending'}
      </div>
    ),
    style: {
      textAlign: 'center',
    },
  },
  {
    Header: '',
    accessor: 'video_thumbnail',
    Cell: ({ value }) => (
      <img src={value} alt="Thumbnail" style={{ width: '100px' }} />
    ),
  },
  {
    Header: 'title',
    accessor: 'video_title',
    width: 190,
    maxWidth: 190,
    Cell: ({ value, row }) => (
      <div style={{
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '190px',
      }}>
        <a href={`https://www.youtube.com/watch?v=${row.original.video_id}`} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      </div>
    ),
  },
  {
    Header: 'mins',
    accessor: 'runtime',
  },
  {
    Header: () => (
      <div style={{ backgroundColor: '#314da8', color: 'black' }}>$$</div>
    ),
    accessor: 'revenue',
    Cell: ({ value }) => `$${value}`,
  },
  {
    Header: '$/hour',
    accessor: 'revenue_per_hour',
    Cell: ({ value }) => `$${value}`,
  },
  {
    Header: 'cpm',
    accessor: 'chats_per_min',
  },
  {
    Header: 'viewers',
    accessor: 'latest_concurrent_viewers',
  },
  {
    Header: 'actions',
    accessor: 'video_id',
    Cell: ({ row }) => (
      <div>
        <FontAwesomeIcon
          icon={faComment}
          className="fa-thin"
          style={{
            color: row.original.is_active ? 'green' : 'grey',
            display: 'inline-block',
            marginLeft: '10px',
            animation: row.original.is_active ? 'blinking 4.5s infinite' : 'none'
          }}
          onClick={() => handleRowClick(row)}
          title="live-chat"
        />
        <FontAwesomeIcon
          icon={faChartLine}
          className="fa-thin"
          style={{
            color: row.original.is_active ? 'green' : 'grey',
            animation: row.original.is_active ? 'blinking 4s infinite' : 'none',
            marginLeft: '10px'
          }}
          onClick={() => handleViewersClick(row.original.video_id)}
          title="live-statistics"
        />
        <FontAwesomeIcon
          icon={faTrash}
          className="fa-thin"
          style={{
            color: 'grey',
            display: 'inline-block',
            marginLeft: '10px'
          }}
          onClick={() => handleRowTrash(row.original.video_id)}
          title="delete"
        />
        <FontAwesomeIcon
          icon={faFlagCheckered}
          className="fa-light"
          style={{
            color: 'grey',
            display: 'inline-block',
            marginLeft: '10px'
          }}
          onClick={() => handleRowForceComplete(row.original.video_id)}
          title="force-completion"
        />
      </div>
    ),
  }
];

export const createVideoHistoryColumns = (
  handleRowClick,
  handleViewersClick,
  handleTranscriptGeneration,
  handleArchivalRequest,
  handleRowTrash,
  handleRowMigrate,
  handleRowRegenTranscript,
  handleBackloadChat,
  setSelectedResult,
  setShowArchivalModal
) => [
  {
    id: 'video_type',
    Header: 'type',
    accessor: 'video_type',
    width: 20,
    maxWidth: 20,
    Cell: ({ value }) => (
      <div style={{
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '90px',
      }}>
        {value}
      </div>
    ),
  },
  {
    id: 'channel',
    Header: 'channel',
    accessor: 'channel_nickname',
    width: 90,
    maxWidth: 90,
    Cell: ({ value, row }) => (
      <div style={{
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '90px',
      }}>
        <a href={row.original.channel_streams_link} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      </div>
    ),
  },
  {
    Header: 'video_id',
    accessor: 'video_id',
    width: 120,
    maxWidth: 120,
    Cell: ({ value, row }) => (
      <div style={{
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '120px',
      }}>
        <a href={`https://www.youtube.com/watch?v=${row.original.video_id}`} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      </div>
    ),
  },
  {
    Header: 'started_pst',
    accessor: 'start_time',
  },
  {
    Header: '',
    accessor: 'video_thumbnail',
    Cell: ({ value, row }) => (
      <img src={value} alt="Thumbnail" style={{ width: '100px' }} />
    ),
  },
  {
    Header: 'title',
    accessor: 'video_title',
    width: 190,
    maxWidth: 190,
    Cell: ({ value, row }) => (
      <div style={{
        whiteSpace: 'wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '190px',
      }}>
        <a href={`https://www.youtube.com/watch?v=${row.original.video_id}`} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      </div>
    ),
  },
  {
    Header: 'mins',
    accessor: 'mins_duration',
  },
  {
    Header: () => (
      <div style={{ backgroundColor: '#314da8', color: 'black' }}>$$</div>
    ),
    accessor: 'superchat_usd',
    Cell: ({ value }) => `$${value}`,
  },
  {
    Header: '$/hour',
    accessor: 'revenue_per_hour',
    Cell: ({ value }) => `$${value}`,
  },
  {
    Header: 'view',
    accessor: 'avg_concurrent_viewers',
  },
  {
    Header: 'cpm',
    accessor: 'avg_chat_rate',
  },
  {
  id: 'actions',
  Header: 'actions',
  accessor: 'video_id',
  Cell: ({ row }) => {
    let hardDriveColor = 'grey';
    let hardDriveAnimation = '';

    if (row.original.is_archiving) {
      hardDriveColor = 'blue';
      hardDriveAnimation = 'blinking 2s infinite';
    } else if (!row.original.is_archiving && row.original.is_archived) {
      hardDriveColor = 'blue';
    }

    const getScrollIconAndStyle = (row) => {
      if (row.original.is_transcribing) {
        let icon, color = 'yellow', animation = 'blinking 4s infinite';

        if (row.original.transcribe_percentage >= 0.75) {
          icon = faCircleThreeQuartersStroke;
        } else if (row.original.transcribe_percentage >= 0.5) {
          icon = faCircleHalfStroke;
        } else if (row.original.transcribe_percentage >= 0.25) {
          icon = faCircleQuarterStroke;
        } else {
          icon = faCircle;
        }

        return { icon, color, animation };
      } else if (!row.original.is_transcribing && row.original.is_transcribed) {
        return {
          icon: faScrollOld,
          color: 'blue',
          animation: ''
        };
      }

      return {
        icon: faScrollOld,
        color: 'grey',
        animation: ''
      };
    }

    const scrollIconAndStyle = getScrollIconAndStyle(row);

    return (
      <div>
        <FontAwesomeIcon
          icon={faHardDrive}
          className="fa-regular fa-fw"
          style={{ marginRight: '1px', marginLeft: '1px', color: hardDriveColor, animation: hardDriveAnimation }}
          onClick={() => handleTranscriptGeneration(row.original.video_id)}
          title="archive-status"
        />
        <FontAwesomeIcon
          icon={scrollIconAndStyle.icon}
          className="fa-thin fa-fw"
          style={{ marginRight: '1px', marginLeft: '1px', color: scrollIconAndStyle.color, animation: scrollIconAndStyle.animation }}
          onClick={() => handleTranscriptGeneration(row.original.video_id)}
          title="request-transcript"
        />
        <FontAwesomeIcon
          icon={faComment}
          className="fa-thin fa-fw"
          style={{ color: 'blue', display: 'inline-block', marginRight: '1px', marginLeft: '1px' }}
          onClick={() => handleRowClick(row)}
          title="chat-history"
        />
        <br />
        <FontAwesomeIcon
          icon={faChartLine}
          className="fa-thin fa-fw"
          style={{ color: 'blue', marginLeft: '1px', marginRight: '1px' }}
          onClick={() => handleViewersClick(row.original.video_id)}
          title="statistics"
        />
        <FontAwesomeIcon
          icon={faDownload}
          className="fa-thin fa-fw"
          style={{ color: 'blue', marginLeft: '1px', marginRight: '1px' }}
          onClick={() => {
            handleArchivalRequest(row);
            setSelectedResult(row);
            setShowArchivalModal(true);
          }}
          title="request-archival-download"
        />
        <FontAwesomeIcon
          icon={faTrash}
          className="fa-thin"
          style={{
            color: 'blue',
            display: 'inline-block',
            marginLeft: '5px'
          }}
          onClick={() => handleRowTrash(row.original.video_id)}
          title="delete"
        />
        <br />
        <FontAwesomeIcon
          icon={faBulldozer}
          className="fa-thin"
          style={{
            color: 'blue',
            display: 'inline-block',
            marginLeft: '1px',
            marginRight: '1px'
          }}
          onClick={() => handleRowMigrate(row.original.video_id, row.original.channel_nickname)}
          title="recover_from_live_capture"
        />
        <FontAwesomeIcon
          icon={faArrowsSpin}
          className="fa-thin"
          style={{
            color: 'blue',
            display: 'inline-block',
            marginLeft: '1px',
            marginRight: '1px'
          }}
          onClick={() => handleRowRegenTranscript(row.original.video_id, row.original.channel_nickname)}
          title="regenerate_transcript"
        />
        <FontAwesomeIcon
          icon={faMessagesDollar}
          className="fa-light"
          style={{
            color: 'blue',
            display: 'inline-block',
            marginLeft: '1px',
            marginRight: '1px'
          }}
          onClick={() => handleBackloadChat(row.original.video_id)}
          title="try_chat_backload"
        />
      </div>
    );
    },
  }
];