import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRobotAstromech  } from '@fortawesome/pro-solid-svg-icons'


const Home = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <h4>
        <FontAwesomeIcon icon={faRobotAstromech} className="fa-thin" style={{ display: 'inline-block', marginRight: '15px' }} /> 
              posterity machine 
        <FontAwesomeIcon icon={faRobotAstromech} className="fa-thin" style={{ display: 'inline-block', marginLeft: '15px' }} />
        </h4>

    </div>
  );
};

export default Home;