export const convertTimeToSeconds = (time) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

export const highlightFilterText = (text, filterText) => {
  const parts = text.split(new RegExp(`(${filterText})`, 'gi'));
  return parts.map((part, i) => 
    part.toLowerCase() === filterText.toLowerCase() ?
      <span key={i} className='highlight-filter-text'>{part}</span> : 
      part
  );
};

export const highlightText = (text, searchText) => {
  const parts = text.split(new RegExp(`(${searchText})`, 'gi'));
  return parts.map((part, i) => 
    part.toLowerCase() === searchText.toLowerCase() ?
      <span key={i} className='highlight-text'>{part}</span> : 
      part
  );
};

export const formatTimestamp = (videoId, seconds) => {
  const pad = (num) => num.toString().padStart(2, '0');
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const sec = seconds % 60;
  const timestampUrl = `https://www.youtube.com/watch?v=${videoId}&t=${seconds}s`;

  return (
    <>
      <a href={timestampUrl} target="_blank" rel="noopener noreferrer">
        timestamp
      </a>
      {' '}@ {pad(hours)}:{pad(minutes)}:{pad(sec)}
    </>
  );
};

export const applyHighlights = (text, searchText, filterText) => {
  if (!searchText || !searchText.searchText) return text;
  let parts = text.split(new RegExp(`(${searchText.searchText})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => 
        part.toLowerCase() === searchText.searchText.toLowerCase() ?
          <span key={i} className='highlight-text'>
            {highlightFilterText(part, filterText)}
          </span> : 
          highlightFilterText(part, filterText)
      )}
    </span>
  );
};