import { useState } from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload, faOctagonXmark } from '@fortawesome/pro-solid-svg-icons';

// A component that handles clip generation and playback
export const ClippingModal = ({
  selectedResult,
  clipModalOpen,
  setClipModalOpen,
  handleYoutubeUploadClick,
}) => {
  const [startTimeOffset, setStartTimeOffset] = useState(0);
  const [clipDuration, setClipDuration] = useState(30);
  const [clipUrl, setClipUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClipGeneration = (result) => {
    if (result) {
      setIsLoading(true);
      const video_id = result.video_id;
      const channel = result.channel_nickname;
      const start_time = result.start_time;
      const adjusted_start_time = start_time + Number(startTimeOffset);
      const duration = clipDuration;

      const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/clip?channel=${channel}&videoid=${video_id}&start=${adjusted_start_time}&duration=${duration}`;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setClipUrl(data.url);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error generating clip:', error);
          setIsLoading(false);
        });
    }
  };

  const handleDownloadClip = (event) => {
    event.stopPropagation();
    const link = document.createElement('a');
    link.href = clipUrl;
    link.download = `${selectedResult.video_id}.mp4`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal
      isOpen={clipModalOpen}
      onRequestClose={() => setClipModalOpen(false)}
      className="clip-modal-content"
      overlayClassName="clip-modal-overlay"
    >
      <div className="modal-header2 close-container2">
        <FontAwesomeIcon
          icon={faOctagonXmark}
          className="modal-close-icon"
          style={{ color: 'red', marginLeft: '20px' }}
          onClick={() => setClipModalOpen(false)}
        />
        {!isLoading && (
          <>
            <FontAwesomeIcon
              icon={faDownload}
              className="modal-download-icon"
              style={{ color: 'blue', marginLeft: '10px', marginRight: '10px' }}
              onClick={handleDownloadClip}
            />
            <FontAwesomeIcon
              icon={faUpload}
              className="modal-upload-icon"
              style={{ color: 'green', marginLeft: '10px', marginRight: '10px' }}
              onClick={handleYoutubeUploadClick}
            />
          </>
        )}
      </div>
      {isLoading ? (
        <p>Generating - please wait...</p>
      ) : (
        <ReactPlayer url={clipUrl} controls={true} playing={true} volume={0.9} width="100%" />
      )}
      <div>
        <label>
          Offset (seconds):
          <input
            type="text"
            value={startTimeOffset}
            onChange={(e) => setStartTimeOffset(e.target.value)}
          />
        </label>
        <label>
          Duration (seconds):
          <input
            type="number"
            value={clipDuration}
            onChange={(e) => setClipDuration(Number(e.target.value))}
          />
        </label>
        <button onClick={() => handleClipGeneration(selectedResult)}> CLIP </button>
      </div>
    </Modal>
  );
};
