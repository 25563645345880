// ./modals/ArchivalModal.js
import React from 'react';
import Modal from 'react-modal';

const ArchivalModal = ({
  isOpen,
  onRequestClose,
  selectedResult,
  requestNote,
  setRequestNote,
  handleArchivalRequestGeneration,
  isLoading,
  responseMessage,
  responseStatus
}) => {
  if (!selectedResult) return null;

  return (
    <Modal 
      className="clip-modal-content" 
      isOpen={isOpen} 
      onRequestClose={onRequestClose}
    >
      <h6>ARCHIVAL REQUEST {selectedResult.video_title}</h6>
      <label>
        Add a request note (optional) 
        <br />
        <input 
          type="text" 
          value={requestNote} 
          onChange={(e) => setRequestNote(e.target.value)} 
        />
      </label>

      <button onClick={handleArchivalRequestGeneration}>
        submit
      </button>

      {isLoading && <p>please wait...</p>}
      <button onClick={onRequestClose}>close</button>
      
      {responseMessage && (
        <div>
          <p>{responseMessage} : {responseStatus}</p>
        </div>
      )}
    </Modal>
  );
};

export default ArchivalModal;