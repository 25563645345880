import React, { useState, useEffect, useContext, useRef } from 'react';
import './searchV3.css';
import { AuthContext, AuthProvider } from '../../AuthContext'; 
import ReactPlayer from 'react-player';
import Select from 'react-select';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScissors, faOctagonXmark } from '@fortawesome/pro-duotone-svg-icons';
import { format, utcToZonedTime } from 'date-fns-tz';
import {
  faScrollOld, faArrowsSpin, faComment, faTrash,
  faChartLine, faDownload, faHardDrive, faCircle,
  faCircleQuarterStroke, faCircleHalfStroke,
  faCircleThreeQuartersStroke, faBulldozer
} from '@fortawesome/pro-solid-svg-icons';

const formatDateTime = (timestamp, isChat = false) => {
  try {
    let parsedDate = new Date(timestamp);
    if (isNaN(parsedDate)) throw new Error('Invalid date format');
    if (isChat) {
      parsedDate = new Date(parsedDate.getTime() + 7 * 60 * 60 * 1000); // Add 7 hours
    }
    const timeZone = 'America/Los_Angeles'; // PST timezone
    const zonedDate = utcToZonedTime(parsedDate, timeZone);
    return format(zonedDate, 'yyyy-MM-dd HH:mm:ss', { timeZone });
  } catch (error) {
    console.error('Error formatting date time:', error);
    return 'Invalid Date';
  }
};

const calculateTranscriptTimestamp = (videoTimestamp, videoStartTime) => {
  try {
    const startDate = new Date(videoTimestamp);
    if (isNaN(startDate)) throw new Error('Invalid video timestamp');

    // Add the video start time (in seconds) to the video timestamp (in milliseconds)
    const newDate = new Date(startDate.getTime() + videoStartTime * 1000);
    const finalDate = new Date(newDate.getTime() + 7 * 60 * 60 * 1000); // Add 7 hours

    // Format the new date and time
    return formatDateTime(finalDate);
  } catch (error) {
    console.error('Error calculating transcript timestamp:', error);
    return 'Invalid Timestamp';
  }
};

const sortSuggestionsByTimestamp = (a, b) => {
  const getTimestamp = (suggestion) => {
    try {
      if (suggestion.highlight) {
        return new Date(calculateTranscriptTimestamp(suggestion.video_timestamp_pst, suggestion.start_time)).getTime();
      } else {
        return new Date(formatDateTime(suggestion.chat_timestamp_pst, true)).getTime();
      }
    } catch (error) {
      console.error('Error getting timestamp:', error);
      return 0;
    }
  };
  return getTimestamp(b) - getTimestamp(a);
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    fontSize: '16px',
    borderColor: state.isFocused ? 'blue' : provided.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px blue' : provided.boxShadow,
    '&:hover': {
      borderColor: state.isFocused ? 'blue' : provided.borderColor,
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: 500,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? 'blue' : 'white',
    '&:hover': {
      backgroundColor: state.isFocused ? 'lightblue' : provided.backgroundColor,
    },
  }),
};

const highlightChatMessage = (message, searchInput) => {
  if (!message) return message;

  const regex = new RegExp(`(${searchInput})`, 'gi');

  return message.split(regex).map((part, index) =>
    part.toLowerCase() === searchInput.toLowerCase() ? (
      <span key={index} style={{ color: 'red' }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

const SearchV3 = () => {
  const [searchInput, setSearchInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resultCount, setResultCount] = useState(null);
  const [expandedVideoId, setExpandedVideoId] = useState(null);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedMode, setSelectedMode] = useState("precision");
  const [selectedType, setSelectedType] = useState(["transcripts"]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');

  const [clipModalOpen, setClipModalOpen] = useState(false);
  const [ArchivalModalOpen, setArchivalModalOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [startTimeOffset, setStartTimeOffset] = useState(0);
  const [requestNote, setRequestNote] = useState('');
  const [clipDuration, setClipDuration] = useState(30);
  const [selectedResult, setSelectedResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clipUrl, setClipUrl] = useState(null);
  const [showClipModal, setShowClipModal] = useState(false);
  const [showArchivalModal, setShowArchivalModal] = useState(false);

  const timestampRef = useRef(0);
  const videoIdRef = useRef(0);
  const { user } = useContext(AuthContext);

  const [imageRefs, setImageRefs] = useState({});
  const [loadAttempted, setLoadAttempted] = useState({});

  const [refreshToken, setRefreshToken] = useState(0);

  useEffect(() => {
    const refs = suggestions.reduce((acc, suggestion) => {
      acc[suggestion.video_id] = React.createRef();
      return acc;
    }, {});
    setImageRefs(refs);
  }, [suggestions]);

  const handleImgValidity = (suggestion, fallbackUrls) => {
    const imgRef = imageRefs[suggestion.video_id];

    if (imgRef && imgRef.current && !loadAttempted[suggestion.video_id]) {
      const nextFallbackIndex = loadAttempted[suggestion.video_id] || 0;
      if (nextFallbackIndex < fallbackUrls.length) {
        imgRef.current.src = fallbackUrls[nextFallbackIndex];
        setLoadAttempted(prev => ({ ...prev, [suggestion.video_id]: nextFallbackIndex + 1 }));
      }
    }
  };

  useEffect(() => {
    setSelectedValue([{ label: "all-channels", value: "<SELECT_ALL>" }]);
  }, []);

  useEffect(() => {
    const storedChannelData = JSON.parse(localStorage.getItem('channel-data') || '{"results":[]}');
    const options = storedChannelData.results.map(channel => ({
      label: channel[2], // channel_nickname
      value: channel[0], // channel_lookup_id
    }));
    options.sort((a, b) => a.label.localeCompare(b.label));

    setChannelOptions([
      { label: "all-channels", value: "<SELECT_ALL>", isSelected: true },
      { label: "clear-selections", value: "<CLEAR_ALL>" },
      { label: "-----", value: "separator", isDisabled: true },
      ...options
    ]);
  }, []);

  const handleSelect = (selectedOptions, actionMeta) => {
    if (actionMeta.action === "select-option" && actionMeta.option.value !== "<SELECT_ALL>") {
      selectedOptions = selectedOptions.filter(option => option.value !== "<SELECT_ALL>");
    }
    if (actionMeta.action === "select-option" && actionMeta.option.value === "<SELECT_ALL>") {
      selectedOptions = [{ label: "all-channels", value: "<SELECT_ALL>" }];
    }

    setSelectedValue(selectedOptions);

    const updatedChannelOptions = channelOptions.map(option => {
      if (option.value === "<SELECT_ALL>" && selectedOptions.length > 1) {
        return { ...option, isSelected: false };
      }
      return {
        ...option,
        isSelected: !!selectedOptions.find(selectedOption => selectedOption.value === option.value)
      };
    });

    setChannelOptions(updatedChannelOptions);
  };

  const isSelectAllSelected = () =>
    channelOptions.filter(option => option.value !== "<SELECT_ALL>" && option.value !== "<CLEAR_ALL>").every(option => option.isSelected);

  const truncate = (str, startIndex, maxLength) => {
    let start = Math.max(0, startIndex - Math.floor(maxLength / 2));
    let end = start + maxLength;
    if (end > str.length) {
      end = str.length;
      start = Math.max(0, end - maxLength);
    }
    return (start > 0 ? '...' : '') + str.substring(start, end) + (end < str.length ? '...' : '');
  };

  const trimDescription = (text) => {
    if (!text) {
      return <span>No description available</span>;
    }
    const maxLength = 250; // Adjust the value as needed
    return truncate(text, 0, maxLength);
  };

  const highlightChatMessage = (message, searchInput) => {
    if (!message) return message;

    const regex = new RegExp(`(${searchInput})`, 'gi');

    return message.split(regex).map((part, index) =>
      part.toLowerCase() === searchInput.toLowerCase() ? (
        <span key={index} style={{ color: 'red' }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const highlightText = (text, videoId, handleTimestampClick) => {
    if (!text) {
      return <span>No text provided</span>;
    }

    let timestamp = null;
    const timestampMatch = text.match(/\[(\d+)\]/);
    if (timestampMatch) {
      timestamp = timestampMatch[1];
      text = text.replace(/\[\d+\]/g, ''); // remove all instances of timestamps
    }

    if (selectedMode === "fuzzy") {
      return text.split(/(<em>.*?<\/em>)/g).map((part, index) =>
        part.startsWith('<em>') ? (
          <a
            key={index}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleTimestampClick(videoId, timestamp);
            }}
            className="highlighted-text-v3"
          >
            {part.slice(4, -5)}
          </a>
        ) : (
          part
        )
      );
    } else {
      const regex = new RegExp(`(${searchInput})`, 'gi');
      const matches = [...text.matchAll(regex)];

      if (matches.length > 0) {
        const matchIndex = matches[0].index;
        text = truncate(text, matchIndex, 325);
      }

      return text.split(regex).map((part, index) =>
        part.toLowerCase() === searchInput.toLowerCase() ? (
          <a
            key={index}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleTimestampClick(videoId, timestamp);
            }}
            className="highlighted-text-v3"
          >
            {part}
          </a>
        ) : (
          part
        )
      );
    }
  };

  const handleTimestampClick = (videoId, timestamp) => {
    videoIdRef.current = videoId;
    timestampRef.current = timestamp; // Update the ref with the new timestamp
    const newVideoUrl = `https://www.youtube.com/watch?v=${videoId}&t=${timestamp}s`;
    setCurrentVideoUrl(newVideoUrl);
  };

  const formatTimestamp = (videoId, seconds) => {
    const pad = (num) => num.toString().padStart(2, '0');
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;

    const timestampUrl = `https://www.youtube.com/watch?v=${videoId}&t=${seconds}s`;

    return (
      <>
        <a href={timestampUrl} target="_blank" rel="noopener noreferrer">
          timestamp
        </a>
        {' '}@ {pad(hours)}:{pad(minutes)}:{pad(sec)}
      </>
    );
  };

  useEffect(() => {
    if (searchInput.trim() === '') {
      setSuggestions([]);
      return;
    }

    const fetchData = async () => {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const formattedTomorrow = tomorrow.toISOString().split('T')[0];

      setLoading(true);
      setResultCount(null);
      try {
        const searchText = encodeURIComponent(searchInput);
        const searchMode = selectedMode;
        const searchBegin = '1999-01-01';
        const searchEnd = formattedTomorrow;
        const searchLimit = 50;

        let searchChannels;

        const isAllChannelsSelected = channelOptions.some(option => option.value === "<SELECT_ALL>" && option.isSelected);

        if (isAllChannelsSelected) {
          searchChannels = 0;
        } else {
          searchChannels = channelOptions
            .filter(option => option.isSelected && option.value !== "<SELECT_ALL>" && option.value !== "<CLEAR_ALL>" && option.value !== "separator")
            .map(option => option.value)
            .join(',');
        }

        const searchTypes = selectedType.join(",");
        const response = await fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/prod/search?u=${user}&s=${searchText}&m=${searchMode}&t=${searchTypes}&c=${searchChannels}&b=${searchBegin}&e=${searchEnd}&l=${searchLimit}`);
        const data = await response.json();

        if (data.results) {
          const transcriptResults = data.results.transcript || [];
          const videoResults = data.results.video || [];
          const chatResults = data.results.chat || [];

          const storedChannelData = JSON.parse(
            localStorage.getItem("channel-data") || '{"results":[]}'
          );
          const channelDataArray = storedChannelData.results;

          const mergedData = [...transcriptResults, ...videoResults, ...chatResults].map(
            (result) => {
              const channelInfo = channelDataArray.find(
                (channel) =>
                  Number(channel[0]) === Number(result.channel_lookup_id)
              );
              const timestamp = result.video_timestamp_pst || result.video_aired_ts_pst;
              return {
                ...result,
                video_timestamp_pst: timestamp ? new Date(timestamp).getTime() : null,
                channel_nickname: channelInfo ? channelInfo[2] : result.channel_nickname,
                channel_title: channelInfo ? channelInfo[4] : result.channel_title, // Use channel_title from channelInfo
                default_channel_thumbnail: channelInfo
                  ? channelInfo[7]
                  : "default_thumbnail_url_here",
              };
            }
          );
          mergedData.sort((a, b) => (a.video_timestamp_pst || 0) - (b.video_timestamp_pst || 0));
          setSuggestions(mergedData);
          setResultCount(mergedData.length);
        } else {
          console.error("No results found or data is not an array:", data.results);
          setSuggestions([]);
          setResultCount(null);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
        setResultCount(null);
      } finally {
        setLoading(false);
      }
    };

    const timeoutId = setTimeout(fetchData, 300);
    return () => clearTimeout(timeoutId);
  }, [searchInput, selectedMode, refreshToken, selectedType]);

  const handlePreciseModeClick = () => { setSelectedMode("precision"); };
  const handleFuzzyModeClick = () => { setSelectedMode("fuzzy"); };

  const handleTypeClick = (type) => {
    setSelectedType((prevSelectedTypes) => {
      if (prevSelectedTypes.includes(type)) {
        return prevSelectedTypes.filter((t) => t !== type);
      } else {
        return [...prevSelectedTypes, type];
      }
    });
  };

  const handleRefresh = () => {
    setRefreshToken(Math.random());
  };

  const toggleExpand = (videoId) => {
    setExpandedVideoId(prev => prev === videoId ? null : videoId);
  };

  const groupedSuggestions = suggestions.reduce((acc, suggestion) => {
    const videoId = suggestion.video_id;
    if (!acc[videoId]) {
      acc[videoId] = {
        video_id: videoId,
        video_title: suggestion.video_title,
        video_thumbnail: suggestion.video_thumbnail,
        channel_lookup_id: suggestion.channel_lookup_id,
        default_channel_thumbnail: suggestion.default_channel_thumbnail,
        channel_title: suggestion.channel_title,
        items: []
      };
    }
    acc[videoId].items.push(suggestion);
    return acc;
  }, {});

  const expandedItems = expandedVideoId ? groupedSuggestions[expandedVideoId]?.items.sort(sortSuggestionsByTimestamp) : [];

  const handleClipGeneration = (result) => {
    if (result) {
      setIsLoading(true);
      const video_id = result.video_id;
      const channel = result.channel_nickname;
      const start_time = result.start_time;
      const adjusted_start_time = start_time + Number(startTimeOffset);
      const duration = clipDuration;
      const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/clip?channel=${channel}&videoid=${video_id}&start=${adjusted_start_time}&duration=${duration}`;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setClipUrl(data.url);
          setShowClipModal(true);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error generating clip:', error);
          setIsLoading(false);
        });
    }
  };

  const openClipModal = (result) => {
    setSelectedResult(result);
    setClipModalOpen(true);
  };

  const handleDownloadClip = (event) => {
    event.stopPropagation();
    const link = document.createElement('a');
    link.href = clipUrl;
    link.download = `${selectedResult.video_id}.mp4`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleTranscriptGeneration = (event, result) => {
    event.stopPropagation();
    const video_id = result.video_id;
    const start_time = result.start_time;
    const adjusted_start_time = start_time;
    const filename = `transcript_${video_id}_${start_time}.txt`;

    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/get_transcript?videoid=${video_id}&start=${adjusted_start_time}`;
    fetch(url)
      .then((response) => {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          link.click();
          window.URL.revokeObjectURL(url);
        });
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  const handleArchivalRequest = (event, result) => {
    event.stopPropagation();
    setSelectedResult(result);
    setRequestNote('');
    setResponseMessage('');
    setResponseStatus('');
    setShowArchivalModal(true);
  };

  const handleArchivalRequestGeneration = (result, requestNote) => {
    const video_id = result.video_id;
    const channel = result.channel_nickname;
    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/request-archival?videoid=${video_id}&username=${user}&channel_nickname=${channel}&note=${requestNote}`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const { message, status } = data;
        setResponseMessage(message);
        setResponseStatus(status);
      })
      .catch((error) => {
        console.error('Error requesting archival:', error);
      });
  };

  return (
    <div className="unified-search-layout-v3">
      <div className="search-input-container-v3">
        <button className={`mode-button-v3 ${selectedMode === "precision" ? "selected" : ""}`} onClick={handlePreciseModeClick}>
          precision-mode
        </button>
        <button className={`mode-button-v3 ${selectedMode === "fuzzy" ? "selected" : ""}`} onClick={handleFuzzyModeClick}>
          fuzzy-mode (unstable)
        </button>
        <button className={`type-button-v3 ${selectedType.includes("transcripts") ? "selected" : ""}`} onClick={() => handleTypeClick("transcripts")}>
          transcripts
        </button>
        <button className={`type-button-v3 ${selectedType.includes("videos") ? "selected" : ""}`} onClick={() => handleTypeClick("videos")}>
          videos
        </button>
        <button className={`type-button-v3 ${selectedType.includes("chats") ? "selected" : ""}`} onClick={() => handleTypeClick("chats")}>
          chats
        </button>
      </div>

      <div className="search-input-container-v3">
        <input
          type="text"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="search"
        />
        {loading && <span className="loading-text-v3">thinking...</span>}
        {resultCount > 0 && <span className="loading-text-v3">{resultCount} results..</span>}
        <button onClick={handleRefresh}>refresh</button>
        <div className="react-select-wrapper-v3" style={{ width: '55%' }}>
          <Select
            options={channelOptions}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            onChange={handleSelect}
            styles={customStyles}
            value={selectedValue}
          />
        </div>
      </div>
      <div className="results-and-preview-v3">
        <div className="suggestions-container-v3">
          <div className="my-container-v3">
            <ul>
              {Object.values(groupedSuggestions).map((group, index) => (
                <li key={index} className="suggestion-item-v3">
                  <div onClick={() => toggleExpand(group.video_id)} className="video-summary-v3">
                    <div className="video-details-v3">
                      <div className="video-meta-v3">
                        <div className="video-title-v3">{group.channel_title} : {group.video_title}</div> {/* Display the channel title */}
                      </div>
                    </div>
                    <img
                      ref={imageRefs[group.video_id]}
                      src={group.video_thumbnail || `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/prod/get-thumbnail?v=${group.video_id}&c=${group.channel_lookup_id}`}
                      alt={`${group.video_title} thumbnail`}
                      className="video-thumbnail-v3"
                      onLoad={() => handleImgValidity(group, [
                        `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/prod/get-thumbnail?v=${group.video_id}&c=${group.channel_lookup_id}`,
                        `${group.default_channel_thumbnail}`,
                        `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/prod/get-thumbnail?v=yt2`
                      ])}
                      onError={(e) => {
                        const nextFallbackIndex = loadAttempted[group.video_id] || 1;
                        if (nextFallbackIndex < [
                          `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/prod/get-thumbnail?v=${group.video_id}&c=${group.channel_lookup_id}`,
                          `${group.default_channel_thumbnail}`,
                          `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/prod/get-thumbnail?v=yt2`
                        ].length) {
                          e.target.src = [
                            `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/prod/get-thumbnail?v=${group.video_id}&c=${group.channel_lookup_id}`,
                            `${group.default_channel_thumbnail}`,
                            `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/prod/get-thumbnail?v=yt2`
                          ][nextFallbackIndex];
                          setLoadAttempted(prev => ({ ...prev, [group.video_id]: nextFallbackIndex + 1 }));
                        }
                      }}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
 
        <div className="side-results-pane-v3">
          {expandedItems && (
            <table className="data">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Timestamp</th>
                  <th>Author</th>
                  <th>Match</th>
                  <th>Actions</th>
                </tr>
              </thead>
<tbody>
  {expandedItems.map((item, itemIndex) => (
    <tr
      key={itemIndex}
      className={item.highlight ? 'transcript-row' : 'chat-row'}
    >
      <td>
        {item.highlight ? (
          <FontAwesomeIcon
            icon={faScrollOld}
            className="fa-duotone"
            title="Transcript"
            style={{ color: 'black' }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faComment}
            className="fa-duotone"
            title="Chat"
            style={{ color: 'black' }}
          />
        )}
      </td>
      <td>
        {item.highlight ? (
          <a href={`https://www.youtube.com/watch?v=${item.video_id}&t=${item.start_time}s`} target="_blank" rel="noopener noreferrer">
            {calculateTranscriptTimestamp(item.video_timestamp_pst, item.start_time)}
          </a>
        ) : (
          formatDateTime(item.chat_timestamp_pst, true)
        )}
      </td>
      <td>
        {item.chat_author_name && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={`${item.chat_author_thumbnail_url}`}
              alt={item.chat_author_name}
              style={{ width: '24px', height: '24px', borderRadius: '50%', marginRight: '8px' }}
            />
            <span>{item.chat_author_name}</span>
          </div>
        )}
      </td>
      <td>
        {item.highlight
          ? highlightText(item.highlight, item.video_id, handleTimestampClick)
          : highlightChatMessage(item.chat_message, searchInput)}
      </td>
      <td>
        {item.highlight && (
          <FontAwesomeIcon
            icon={faScissors}
            className="fa-duotone"
            title="generate-clip"
            onClick={() => openClipModal(item)}
            style={{ color: 'blue', cursor: 'pointer', marginLeft: '10px' }}
          />
        )}
        {item.highlight && (
          <FontAwesomeIcon
            icon={faScrollOld}
            className="fa-duotone"
            title="request-transcript"
            onClick={(event) => handleTranscriptGeneration(event, item)}
            style={{ color: 'blue', cursor: 'pointer', marginLeft: '10px' }}
          />
        )}
        {item.highlight && (
          <FontAwesomeIcon
            icon={faDownload}
            className="fa-duotone"
            title="request-archival-download"
            onClick={(event) => handleArchivalRequest(event, item)}
            style={{ color: 'blue', cursor: 'pointer', marginLeft: '10px' }}
          />
        )}
      </td>
    </tr>
  ))}
</tbody>
            </table>
          )}
        </div>
      </div>

      {clipModalOpen && (
        <Modal
          className="clip-modal-content-v3"
          isOpen={clipModalOpen}
          onRequestClose={() => setClipModalOpen(false)}
        >
          <h6>clip {selectedResult && selectedResult.video_title}</h6>
          <label>
            offset--s--(secs)
            <input type="text" value={startTimeOffset} onChange={(e) => setStartTimeOffset(e.target.value)} />
          </label>
          <label>
            duration-(secs)
            <input type="number" value={clipDuration} onChange={(e) => setClipDuration(Number(e.target.value))} />
          </label>
          <button onClick={() => handleClipGeneration(selectedResult)}> generate clip</button>
          {isLoading && <p>please wait...</p>}
        </Modal>
      )}

      {selectedResult && (
        <Modal className="clip-modal-content-v3" isOpen={ArchivalModalOpen} onRequestClose={() => setArchivalModalOpen(false)}>
          <h6>ARCHIVAL REQUEST {selectedResult.video_title}</h6>
          <label>
            Add a request note (optional)
            <br />
            <input type="text" value={requestNote} onChange={(e) => setRequestNote(e.target.value)} />
          </label>
          <button onClick={() => handleArchivalRequestGeneration(selectedResult, requestNote)}> submit</button>
          {isLoading && <p>please wait...</p>}
          <button onClick={() => setArchivalModalOpen(false)}>close</button>
          {responseMessage && <div><p>{responseMessage} : {responseStatus}</p></div>}
        </Modal>
      )}

      {showClipModal && (
        <Modal isOpen={showClipModal} onRequestClose={() => setShowClipModal(false)} className="react-modal2">
          <div className="modal-header2 close-container2">
            <FontAwesomeIcon
              icon={faOctagonXmark}
              className="modal-close-icon"
              style={{ color: 'red', marginLeft: '20px' }}
              onClick={() => setShowClipModal(false)}
            />
            {!isLoading && (
              <FontAwesomeIcon
                icon={faDownload}
                className="modal-download-icon"
                style={{ color: 'blue', marginLeft: '10px', marginRight: '10px' }}
                onClick={handleDownloadClip}
              />
            )}
          </div>
          {isLoading ? (
            <p>Generating - please wait...</p>
          ) : (
            <ReactPlayer url={clipUrl} controls={true} playing={true} volume={0.90} width='100%' />
          )}
        </Modal>
      )}

      {showArchivalModal && (
        <Modal
          isOpen={showArchivalModal}
          onRequestClose={() => {
            setRequestNote('');
            setResponseMessage('');
            setResponseStatus('');
            setShowArchivalModal(false);
          }}
          className="react-modal2"
        >
          <div className="modal-header2 close-container2">
            <FontAwesomeIcon
              icon={faOctagonXmark}
              className="modal-close-icon"
              style={{ color: 'red', marginLeft: '20px' }}
              onClick={() => setShowArchivalModal(false)}
            />
          </div>
          {isLoading ? (
            <p>Requesting.. please wait</p>
          ) : (
            <p>Requested.. please check the archivals page soon for a download link....</p>
          )}
        </Modal>
      )}
    </div>
  );
};

export default SearchV3;
