// ./modals/ArchivalStatusModal.js
import React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faOctagonXmark, faDownload } from '@fortawesome/pro-solid-svg-icons';

const ArchivalStatusModal = ({
  isOpen,
  onRequestClose,
  isLoading,
  handleArchivalRequest
}) => {
  return (
    <Modal 
      isOpen={isOpen} 
      onRequestClose={onRequestClose}
      className="react-modal2"
    >
      <div className="modal-header2 close-container2">
        <FontAwesomeIcon 
          icon={faOctagonXmark} 
          className="modal-close-icon" 
          style={{ color: 'red', marginLeft: '20px' }} 
          onClick={onRequestClose}
        />
        {!isLoading && 
          <FontAwesomeIcon 
            icon={faDownload} 
            className="modal-download-icon" 
            style={{ color: 'blue', marginLeft: '10px', marginRight: '10px' }} 
            onClick={handleArchivalRequest} 
          />
        }
      </div>
      {isLoading ? (
        <p>Requesting.. please wait</p>
      ) : (
        <p>Requested.. please check the archivals page soon for a download link....</p>
      )}
    </Modal>
  );
};

export default ArchivalStatusModal;