import React, { useState, useEffect, useContext, useRef } from 'react';
import './searchV2.css';
import { AuthContext, AuthProvider } from '../../AuthContext'; 
import ReactPlayer from 'react-player';
import Modal from 'react-modal';
import Select from 'react-select';

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString("en-US", {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }).toUpperCase();
};

const formatDateTime = (timestamp) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const calculateTranscriptTimestamp = (videoTimestamp, videoStartTime) => {
  const startDate = new Date(videoTimestamp);
  const newDate = new Date(startDate.getTime() + videoStartTime * 1000);
  console.log('videoTimestamp:',videoTimestamp,'videoStartTime:',videoStartTime,'startDate:',startDate,'newDate:',newDate)

  return formatDateTime(newDate);
};

const sortSuggestionsByTimestamp = (a, b) => {
  const getTimestamp = (suggestion) => {
    if (!!suggestion.highlight) {
      console.log('transcript timestamp sorting')
      return new Date(calculateTranscriptTimestamp(suggestion.video_timestamp_pst, suggestion.start_time)).getTime();
    } else {
      console.log('chat timestamp sorting')
      return new Date(formatDateTime(suggestion.chat_timestamp_pst)).getTime();
    }
  };

  return getTimestamp(b) - getTimestamp(a);
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    fontSize: '16px',
    borderColor: state.isFocused ? 'blue' : provided.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px blue' : provided.boxShadow,
    '&:hover': {
      borderColor: state.isFocused ? 'blue' : provided.borderColor,
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: 500,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '14px',
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? 'blue' : 'white',
    '&:hover': {
      backgroundColor: state.isFocused ? 'lightblue' : provided.backgroundColor,
    },
  }),
};

const SearchV2 = () => {
  const [searchInput, setSearchInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resultCount, setResultCount] = useState(null);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedMode, setSelectedMode] = useState("precision"); 
  const [selectedType, setSelectedType] = useState(["transcripts"]); 

  const timestampRef = useRef(0);
  const videoIdRef = useRef(0);
  const { user } = useContext(AuthContext);

  const [imageRefs, setImageRefs] = useState({});
  const [loadAttempted, setLoadAttempted] = useState({});

  const [refreshToken, setRefreshToken] = useState(0);
  
  useEffect(() => {
    const refs = suggestions.reduce((acc, suggestion) => {
      acc[suggestion.video_id] = React.createRef();
      return acc;
    }, {});
    setImageRefs(refs);
  }, [suggestions]);

  const handleImgValidity = (suggestion, fallbackUrls) => {
    const imgRef = imageRefs[suggestion.video_id];
    
    if (imgRef && imgRef.current && !loadAttempted[suggestion.video_id]) {
      const nextFallbackIndex = loadAttempted[suggestion.video_id] || 0;
      if (nextFallbackIndex < fallbackUrls.length) {
        imgRef.current.src = fallbackUrls[nextFallbackIndex];
        setLoadAttempted(prev => ({ ...prev, [suggestion.video_id]: nextFallbackIndex + 1 }));
      }
    }
  };

  const [channelOptions, setChannelOptions] = useState([
    { label: "all-channels", value: "<SELECT_ALL>", isSelected: true }
  ]);

  useEffect(() => {
    setSelectedValue([{ label: "all-channels", value: "<SELECT_ALL>" }]);
  }, []);

  useEffect(() => {
    const storedChannelData = JSON.parse(localStorage.getItem('channel-data') || '{"results":[]}');
    const options = storedChannelData.results.map(channel => ({
      label: channel[2], // channel_nickname
      value: channel[0], // channel_lookup_id
    }));
    options.sort((a, b) => a.label.localeCompare(b.label));

    setChannelOptions([
      { label: "all-channels", value: "<SELECT_ALL>", isSelected: true },
      { label: "clear-selections", value: "<CLEAR_ALL>" },
      { label: "-----", value: "separator", isDisabled: true },
      ...options
    ]);
  }, []);

  const handleSelect = (selectedOptions, actionMeta) => {
    if (actionMeta.action === "select-option" && actionMeta.option.value !== "<SELECT_ALL>") {
      selectedOptions = selectedOptions.filter(option => option.value !== "<SELECT_ALL>");
    }
    if (actionMeta.action === "select-option" && actionMeta.option.value === "<SELECT_ALL>") {
      selectedOptions = [{ label: "all-channels", value: "<SELECT_ALL>" }];
    }

    setSelectedValue(selectedOptions);

    const updatedChannelOptions = channelOptions.map(option => {
      if (option.value === "<SELECT_ALL>" && selectedOptions.length > 1) {
        return { ...option, isSelected: false };
      }
      return {
        ...option,
        isSelected: !!selectedOptions.find(selectedOption => selectedOption.value === option.value)
      };
    });

    setChannelOptions(updatedChannelOptions);
  };

  const isSelectAllSelected = () =>
    channelOptions.filter(option => option.value !== "<SELECT_ALL>" && option.value !== "<CLEAR_ALL>").every(option => option.isSelected);

  const truncate = (str, startIndex, maxLength) => {
    let start = Math.max(0, startIndex - Math.floor(maxLength / 2));
    let end = start + maxLength;
    if (end > str.length) {
      end = str.length;
      start = Math.max(0, end - maxLength);
    }
    return (start > 0 ? '...' : '') + str.substring(start, end) + (end < str.length ? '...' : '');
  };

  const trimDescription = (text) => {
    if (!text) {
      return <span>No description available</span>;
    }
    const maxLength = 250; // Adjust the value as needed
    return truncate(text, 0, maxLength);
  };

  const highlightChatMessage = (message, searchInput) => {
    if (!message) return message;

    const regex = new RegExp(`(${searchInput})`, 'gi');

    return message.split(regex).map((part, index) =>
      part.toLowerCase() === searchInput.toLowerCase() ? (
        <span key={index} style={{ color: 'red' }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const highlightText = (text, videoId, handleTimestampClick) => {
    if (!text) {
      return <span>No text provided</span>;
    }

    let timestamp = null;
    const timestampMatch = text.match(/\[(\d+)\]/);
    if (timestampMatch) {
      timestamp = timestampMatch[1];
      text = text.replace(/\[\d+\]/g, ''); // remove all instances of timestamps
    }

    if (selectedMode === "fuzzy") {
      return text.split(/(<em>.*?<\/em>)/g).map((part, index) =>
        part.startsWith('<em>') ? (
          <a
            key={index}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleTimestampClick(videoId, timestamp);
            }}
            className="highlighted-text"
          >
            {part.slice(4, -5)}
          </a>
        ) : (
          part
        )
      );
    } else {
      const regex = new RegExp(`(${searchInput})`, 'gi');
      const matches = [...text.matchAll(regex)];

      if (matches.length > 0) {
        const matchIndex = matches[0].index;
        text = truncate(text, matchIndex, 325);
      }

      return text.split(regex).map((part, index) =>
        part.toLowerCase() === searchInput.toLowerCase() ? (
          <a
            key={index}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleTimestampClick(videoId, timestamp);
            }}
            className="highlighted-text"
          >
            {part}
          </a>
        ) : (
          part
        )
      );
    }
  };

  const handleTimestampClick = (videoId, timestamp) => {
    videoIdRef.current = videoId;
    timestampRef.current = timestamp; // Update the ref with the new timestamp
    const newVideoUrl = `https://www.youtube.com/watch?v=${videoId}&t=${timestamp}s`;
    setCurrentVideoUrl(newVideoUrl);
  };

  const formatTimestamp = (videoId, seconds) => {
    const pad = (num) => num.toString().padStart(2, '0');
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;

    const timestampUrl = `https://www.youtube.com/watch?v=${videoId}&t=${seconds}s`;

    return (
      <>
        <a href={timestampUrl} target="_blank" rel="noopener noreferrer">
          timestamp
        </a>
        {' '}@ {pad(hours)}:{pad(minutes)}:{pad(sec)}
      </>
    );
  };

  useEffect(() => {
    if (searchInput.trim() === '') {
      setSuggestions([]);
      return;
    }

    const fetchData = async () => {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const formattedTomorrow = tomorrow.toISOString().split('T')[0];

      setLoading(true);
      setResultCount(null);
      try {
        const searchText = encodeURIComponent(searchInput);
        const searchMode = selectedMode;
        const searchBegin = '1999-01-01';
        const searchEnd = formattedTomorrow;
        const searchLimit = 50;

        let searchChannels;

        const isAllChannelsSelected = channelOptions.some(option => option.value === "<SELECT_ALL>" && option.isSelected);

        if (isAllChannelsSelected) {
          searchChannels = 0;
        } else {
          searchChannels = channelOptions
            .filter(option => option.isSelected && option.value !== "<SELECT_ALL>" && option.value !== "<CLEAR_ALL>" && option.value !== "separator")
            .map(option => option.value)
            .join(',');
        }    

        const searchTypes = selectedType.join(",");
        const response = await fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/prod/search?u=${user}&s=${searchText}&m=${searchMode}&t=${searchTypes}&c=${searchChannels}&b=${searchBegin}&e=${searchEnd}&l=${searchLimit}`);
        const data = await response.json();

        if (data.results) {
          const transcriptResults = data.results.transcript || [];
          const videoResults = data.results.video || [];
          const chatResults = data.results.chat || [];

          const storedChannelData = JSON.parse(
            localStorage.getItem("channel-data") || '{"results":[]}'
          );
          const channelDataArray = storedChannelData.results;

          const mergedData = [...transcriptResults, ...videoResults, ...chatResults].map(
            (result) => {
              const channelInfo = channelDataArray.find(
                (channel) =>
                  Number(channel[0]) === Number(result.channel_lookup_id)
              );
              const timestamp = result.video_timestamp_pst || result.video_aired_ts_pst;
              return {
                ...result,
                video_timestamp_pst: timestamp ? new Date(timestamp).getTime() : null,
                channel_nickname: channelInfo ? channelInfo[2] : result.channel_nickname,
                default_channel_thumbnail: channelInfo
                  ? channelInfo[7]
                  : "default_thumbnail_url_here",
              };
            }
          );

          setSuggestions(mergedData);
          setResultCount(mergedData.length);
        } else {
          console.error("No results found or data is not an array:", data.results);
          setSuggestions([]);
          setResultCount(null);
        }
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
        setResultCount(null);
      } finally {
        setLoading(false);
      }
    };

    const timeoutId = setTimeout(fetchData, 300);
    return () => clearTimeout(timeoutId);
  }, [searchInput, selectedMode, refreshToken, selectedType]);

  const handlePreciseModeClick = () => { setSelectedMode("precision"); };
  const handleFuzzyModeClick = () => { setSelectedMode("fuzzy"); };

  const handleTypeClick = (type) => {
    setSelectedType((prevSelectedTypes) => {
      if (prevSelectedTypes.includes(type)) {
        return prevSelectedTypes.filter((t) => t !== type);
      } else {
        return [...prevSelectedTypes, type];
      }
    });
  };

  const handleRefresh = () => {
    setRefreshToken(Math.random());
  };

  const sortedSuggestions = suggestions.slice().sort(sortSuggestionsByTimestamp);

return (
  <div className="unified-search-layout">
    <div className="search-input-container">
      <button
        className={`mode-button ${selectedMode === "precision" ? "selected" : ""}`}
        onClick={handlePreciseModeClick}
      >
        precision-mode
      </button>
      <button
        className={`mode-button ${selectedMode === "fuzzy" ? "selected" : ""}`}
        onClick={handleFuzzyModeClick}
      >
        fuzzy-mode (unstable)
      </button>
      <pre>         </pre>
      <button
        className={`type-button ${selectedType.includes("transcripts") ? "selected" : ""}`}
        onClick={() => handleTypeClick("transcripts")}
      >
        transcripts
      </button>
      <button
        className={`type-button ${selectedType.includes("videos") ? "selected" : ""}`}
        onClick={() => handleTypeClick("videos")}
      >
        videos
      </button>
      <button
        className={`type-button ${selectedType.includes("chats") ? "selected" : ""}`}
        onClick={() => handleTypeClick("chats")}
      >
        chats
      </button>
    </div>

    <div className="search-input-container">
      <input
        type="text"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder="                search"
      />
      {loading && <span className="loading-text">thinking...</span>}
      {resultCount > 0 && <span className="loading-text">{resultCount} results..</span>}
      <pre> </pre>
      <button onClick={handleRefresh}>refresh</button>
      <pre>       </pre>  
      <div className="react-select-wrapper" style={{ width: '55%' }}>
        <Select
          options={channelOptions}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          onChange={handleSelect}
          styles={customStyles} 
          value={selectedValue}
        />
      </div>
    </div>
    <div className="results-and-preview">
      <div className="suggestions-container">
        <div className="my-container">
          <ul>
            {sortedSuggestions.map((suggestion, index) => {
              const fallbackUrls = [
                `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/prod/get-thumbnail?v=${suggestion.video_id}&c=${suggestion.channel_lookup_id}`,
                `${suggestion.default_channel_thumbnail}`,
                `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/prod/get-thumbnail?v=yt2`
              ];

              const isChat = !!suggestion.chat_message;
              const isTranscript = !!suggestion.highlight;

              const timestamp = isTranscript
                ? calculateTranscriptTimestamp(suggestion.video_timestamp_pst, suggestion.start_time)
                : formatDateTime(suggestion.chat_timestamp_pst);

              return (
                <li key={index} className="suggestion-item">
                  <img
                    ref={imageRefs[suggestion.video_id]}
                    src={suggestion.video_thumbnail || fallbackUrls[0]}
                    alt={`${suggestion.video_title} thumbnail`}
                    className="video-thumbnail"
                    onLoad={() => handleImgValidity(suggestion, fallbackUrls)}
                    onClick={() => window.open(`https://www.youtube.com/watch?v=${suggestion.video_id}`, '_blank')}
                    onError={(e) => {
                      const nextFallbackIndex = loadAttempted[suggestion.video_id] || 1;
                      if (nextFallbackIndex < fallbackUrls.length) {
                        e.target.src = fallbackUrls[nextFallbackIndex];
                        setLoadAttempted(prev => ({ ...prev, [suggestion.video_id]: nextFallbackIndex + 1 }));
                      }
                    }}
                  />
                  <div className="video-details">
                    <div className="video-meta">
                      <div className="video-date">{timestamp}</div>
                      <div className="channel-nickname">channel: {suggestion.channel_nickname || 'unknown'}</div>
                    </div>
                    <div className="video-title">{suggestion.video_title}</div>
                    <div className="video-description">
                      {isChat && (
                        <>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img
                              src={suggestion.chat_author_thumbnail_url}
                              alt={`${suggestion.chat_author_name} thumbnail`}
                              className="chat-author-thumbnail"
                              style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '8px' }}
                            />
                            <div>
                              {suggestion.chat_author_name}
                              {suggestion.chat_value_usd !== "0" && (
                                <div className="chat-value" style={{ color: 'green' }}>
                                  ${suggestion.chat_value_usd}
                                </div>
                              )}
                            </div>
                          </div>
                          <div>{highlightChatMessage(suggestion.chat_message, searchInput)}</div>
                        </>
                      )}
                      {isTranscript && highlightText(suggestion.highlight, suggestion.video_id, handleTimestampClick)}
                      {!isChat && !isTranscript && trimDescription(suggestion.video_description)}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="video-player-pane">
        {currentVideoUrl && (
          <>
            <ReactPlayer url={currentVideoUrl} playing controls />
            <div className="timestamped-link-container">
              {formatTimestamp(videoIdRef.current, timestampRef.current)}
              <p>If the wrong timestamp is loading: Right-click the link above and select "Open link in incognito window" to prevent YouTube from resuming @ your last watched position. Or navigate manually.</p>
            </div>
          </>
        )}
      </div>
    </div>
  </div>
);
            };

export default SearchV2;
