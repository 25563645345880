import React, { useState, useEffect, useContext, useCallback } from 'react';
import Modal from "react-modal";
import { AuthContext } from '../../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faScrollOld, faArrowsSpin, faComment, faTrash,
  faChartLine, faDownload, faHardDrive, faCircle,
  faCircleQuarterStroke, faCircleHalfStroke,
  faCircleThreeQuartersStroke, faBulldozer, faMessagesDollar, faFlagCheckered
} from '@fortawesome/pro-solid-svg-icons';
import '../../css/animation.css';
import '../../css/animation_frozen.css';
import '../../css/gantt.css';
import '../../css/tables.css';
import ChatModal from '../../modals/modalChats.js';
import 'chart.js/auto';
import ViewerChartModal from '../../ViewerChartModal';
import handleTranscriptGeneration from '../../functions/transcript.js';

import ChannelDropdown from './componentChannelDropdown';
import DataTable from './componentDataTable';
import { createActiveAndScheduledColumns, createVideoHistoryColumns } from './componentColumnDefinitions';
import { useHandlers } from './componentHandlers';

import ReactGA from "react-ga4";
ReactGA.send({ hitType: "pageview", page: "/UpcomingStreams", title: "streams" });

const mergeAndFilterStreams = (upcomingStreams, completedStreams, currentTime) => {
  const oneHourInMs = 60 * 60 * 1000;
  const twentyFourHoursInMs = 24 * oneHourInMs;
  const twentyFourHoursAgo = new Date(currentTime.getTime() - twentyFourHoursInMs);

  const transformedCompletedStreams = completedStreams.map(stream => ({
    ...stream,
    stream_scheduled_pst: stream.start_time,
    video_title: null,
    is_active: new Date(stream.end_time) > currentTime
  }));

  const filteredCompletedStreams = transformedCompletedStreams.filter(stream => {
    const endTime = new Date(stream.end_time);
    return endTime >= twentyFourHoursAgo && endTime <= currentTime;
  });

  const combinedStreams = [...upcomingStreams, ...filteredCompletedStreams];
  combinedStreams.sort((a, b) => new Date(a.stream_scheduled_pst) - new Date(b.stream_scheduled_pst));
  return combinedStreams;
};


const RouteStreams = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const { user } = useContext(AuthContext);
  const [viewerData, setViewerData] = useState([]);
  const [isChartModalOpen, setIsChartModalOpen] = useState(false);
  const [activeAndScheduledVideoTableData, setActiveAndScheduledVideoTableData] = useState([]);
  const [historyTableData, setHistoryTableData] = useState([]);
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [showAllChats, setShowAllChats] = useState(false);
  const [chatFilter, setChatFilter] = useState('');
  const [combinedStreams, setCombinedStreams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ArchivalModalOpen, setArchivalModalOpen] = useState(false);
  const defaultRequestNote = '';
  const [requestNote, setRequestNote] = useState(defaultRequestNote);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const [selectedResult, setSelectedResult] = useState(null);
  const [showArchivalModal, setShowArchivalModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRegenConfirmModal, setShowRegenConfirmModal] = useState(false);
  const [ShowChatBackloadConfirmModal, setShowChatBackloadConfirmModal] = useState(false);
  const [showForceCompleteConfirmModal, setShowForceCompleteConfirmModal] = useState(false);
  const [showMigrateConfirmModal, setShowMigrateConfirmModal] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [currentChannelNickname, setCurrentChannelNickname] = useState(null);
  
  const [channelNicknames, setChannelNicknames] = useState([]);
  const [selectedChannelNicknames, setSelectedChannelNicknames] = useState(new Set());
  const [lastSelectedChannels, setLastSelectedChannels] = useState(new Set());
  const [filteredActiveAndScheduledVideoTableData, setFilteredActiveAndScheduledVideoTableData] = useState([]);
  const [filteredHistoryTableData, setFilteredHistoryTableData] = useState([]);

  // New state for add-stream input
  const [newStreamId, setNewStreamId] = useState('');

// In streams2.js, you need to pass all these values in useHandlers:
 const handlers = useHandlers({
    user,
    modalIsOpen,
    setModalIsOpen,
    setExpandedRowId,
    setViewerData,
    setIsChartModalOpen,
    setCurrentVideoId,
    setCurrentChannelNickname,
    setShowConfirmModal,
    setShowPermissionModal,
    setShowChatBackloadConfirmModal,
    setShowRegenConfirmModal,
    setShowMigrateConfirmModal,
    setRequestNote,
    setResponseMessage,
    setResponseStatus,
    setArchivalModalOpen,
    setSelectedResult,
    setShowArchivalModal,
    setIsLoading,
    defaultRequestNote,
    setShowForceCompleteConfirmModal,
    setNewStreamId,
    currentVideoId,
    currentChannelNickname,
    newStreamId
  });

  useEffect(() => {
    if (showArchivalModal) {
      setArchivalModalOpen(false);
    }
  }, [showArchivalModal]);

  useEffect(() => {
    // This code will run after setSelectedResult has updated the state
  }, [selectedResult]);


  const ViewerChart = () => (
    <Modal
      isOpen={isChartModalOpen}
      onRequestClose={() => setIsChartModalOpen(false)}
      contentLabel="Viewer Chart"
    >
      <ViewerChartModal viewerData={viewerData} />
    </Modal>
  );

const columnsActiveAndScheduledVideos = React.useMemo(
  () => createActiveAndScheduledColumns(
    handlers.handleRowClick,
    handlers.handleViewersClick,
    handlers.handleRowTrash,
    handlers.handleRowForceComplete
  ),
  [handlers]
);
const columnsVideoHistory = React.useMemo(
  () => createVideoHistoryColumns(
    handlers.handleRowClick,
    handlers.handleViewersClick,
    handleTranscriptGeneration,
    handlers.handleArchivalRequest,
    handlers.handleRowTrash,
    handlers.handleRowMigrate,
    handlers.handleRowRegenTranscript,
    handlers.handleBackloadChat,
    setSelectedResult,
    setShowArchivalModal
  ),
  [
    handlers,
    handleTranscriptGeneration, 
    setSelectedResult, 
    setShowArchivalModal
  ]
);

  const fetchData = useCallback(async () => {
    const responseActiveAndScheduledVideos = await fetch(
      `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/streams?option=active_and_upcoming_streams&username=${user}`
    );
    const jsonActiveAndScheduledVideos = await responseActiveAndScheduledVideos.json();
    const parsedActiveAndScheduledVideos = jsonActiveAndScheduledVideos.results;
    setActiveAndScheduledVideoTableData(parsedActiveAndScheduledVideos);

    const responseVideoHistory = await fetch(
      `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/streams?option=video_history&username=${user}`
    );
    const jsonResponseVideoHistory = await responseVideoHistory.json();
    const parsedVideoHistory = jsonResponseVideoHistory.results;
    setHistoryTableData(parsedVideoHistory);

    const allChannelNicknames = new Set([...parsedActiveAndScheduledVideos, ...parsedVideoHistory].map(stream => stream.channel_nickname));
    setChannelNicknames([...allChannelNicknames]);

    // Update selectedChannelNicknames based on lastSelectedChannels
    if (lastSelectedChannels.size === 0) {
      // If it's the first load, select all channels
      setSelectedChannelNicknames(new Set([...allChannelNicknames]));
      setLastSelectedChannels(new Set([...allChannelNicknames]));
    } else {
      // Keep only the channels that still exist and were previously selected
      const updatedSelection = new Set([...lastSelectedChannels].filter(channel => allChannelNicknames.has(channel)));
      setSelectedChannelNicknames(updatedSelection);
    }
  }, [user, lastSelectedChannels]);

  useEffect(() => {
    fetchData();
    const timer = setInterval(fetchData, 10000);
    return () => clearInterval(timer);
  }, [fetchData]);

  useEffect(() => {
    const currentTime = new Date();
    const mergedStreams = mergeAndFilterStreams(activeAndScheduledVideoTableData, historyTableData, currentTime);
    
    const filteredActiveAndScheduled = activeAndScheduledVideoTableData.filter(stream => 
      selectedChannelNicknames.has(stream.channel_nickname)
    );
    const filteredHistory = historyTableData.filter(stream => 
      selectedChannelNicknames.has(stream.channel_nickname)
    );
    
    setFilteredActiveAndScheduledVideoTableData(filteredActiveAndScheduled);
    setFilteredHistoryTableData(filteredHistory);

    const filteredMergedStreams = mergedStreams.filter(stream => 
      selectedChannelNicknames.has(stream.channel_nickname)
    );
    setCombinedStreams(filteredMergedStreams);
  }, [activeAndScheduledVideoTableData, historyTableData, selectedChannelNicknames]);

  const handleChannelSelection = (newSelection) => {
    setSelectedChannelNicknames(newSelection);
    setLastSelectedChannels(newSelection);
  };


 return (
  <>
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '1400px' }}>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          marginBottom: '10px',
          padding: '0 30px'
        }}>
          <ChannelDropdown
            channelNicknames={channelNicknames}
            selectedChannelNicknames={selectedChannelNicknames}
            onChange={handleChannelSelection}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              value={newStreamId}
              onChange={(e) => setNewStreamId(e.target.value)}
              placeholder="add-stream"
              style={{ 
                marginRight: '10px',
                padding: '5px 10px',
                backgroundColor: '#424242',
                color: '#fff',
                border: 'none',
                borderRadius: '4px'
              }}
            />
            <button 
              onClick={handlers.handleAddStream}
              style={{
                padding: '5px 15px',
                backgroundColor: '#606060',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              Go
            </button>
          </div>
        </div>

      <DataTable
        data={filteredActiveAndScheduledVideoTableData}
        columns={columnsActiveAndScheduledVideos}
        expandedRowId={expandedRowId}
        onRowClick={handlers.handleRowClick}
      />
          <ChatModal
            key={expandedRowId}
            videoId={expandedRowId}
            closeExpandedRow={handlers.closeModal}  // Changed
            showAllChats={showAllChats}
            setShowAllChats={setShowAllChats}
            chatFilter={chatFilter}
            setChatFilter={setChatFilter}
            closeModal={handlers.closeModal}        // Changed
            isModalOpen={modalIsOpen}
            setIsModalOpen={setModalIsOpen}
          />

          {isChartModalOpen && (
            <ViewerChartModal
              viewerData={viewerData}
              isChartModalOpen={isChartModalOpen}
              closeChartModal={() => setIsChartModalOpen(false)}
            />
          )}

      <DataTable
        data={filteredHistoryTableData}
        columns={columnsVideoHistory}
      />

          <Modal className='clip-modal-content'
            isOpen={showMigrateConfirmModal}
            onRequestClose={() => setShowMigrateConfirmModal(false)}
          >
            <div>Are you sure you want to archive the livestream from a capture file?</div>
            <div>
              <button onClick={handlers.handleMigrateConfirmYes}>Yes</button>
              <button onClick={() => setShowMigrateConfirmModal(false)}>No</button>
            </div>
          </Modal>

          <Modal className='clip-modal-content'
            isOpen={showRegenConfirmModal}
            onRequestClose={() => setShowRegenConfirmModal(false)}
          >
            <div>Are you sure you want to regen the transcript from a capture file?</div>
            <div>
              <button onClick={handlers.handleRegenConfirmYes}>Yes</button>
              <button onClick={() => setShowRegenConfirmModal(false)}>No</button>
            </div>
          </Modal>

          <Modal className='clip-modal-content'
            isOpen={ShowChatBackloadConfirmModal}
            onRequestClose={() => setShowChatBackloadConfirmModal(false)}
          >
            <div>Are you sure you want to try backloading chat object?</div>
            <div>
              <button onClick={handlers.handleBackloadChatYes}>Yes</button>
              <button onClick={() => setShowChatBackloadConfirmModal(false)}>No</button>
            </div>
          </Modal>

          <Modal className='clip-modal-content'
            isOpen={showForceCompleteConfirmModal}
            onRequestClose={() => setShowForceCompleteConfirmModal(false)}
          >
            <div>Are you sure you want to force stream complete?</div>
            <div>
              <button onClick={handlers.handleForceCompleteYes}>Yes</button>
              <button onClick={() => setShowForceCompleteConfirmModal(false)}>No</button>
            </div>
          </Modal>

          <Modal className='clip-modal-content'
            isOpen={showConfirmModal}
            onRequestClose={() => setShowConfirmModal(false)}
          >
            <div>Are you sure?</div>
            <div>
              <button onClick={handlers.handleConfirmYes}>Yes</button>
              <button onClick={() => setShowConfirmModal(false)}>No</button>
            </div>
          </Modal>

          <Modal className='clip-modal-content'
            isOpen={showPermissionModal}
            onRequestClose={() => setShowPermissionModal(false)}
          >
            <div>You do not have permission</div>
            <button onClick={() => setShowPermissionModal(false)}>OK</button>
          </Modal>

          {showArchivalModal && selectedResult && (
            <Modal
              className='clip-modal-content'
              isOpen={showArchivalModal}
              onRequestClose={() => {
                setRequestNote(defaultRequestNote);
                setResponseMessage('');
                setResponseStatus('');
                setShowArchivalModal(false);
                setIsLoading(false);
              }}
            >
              <h6>ARCHIVAL REQUEST {selectedResult?.original.video_title}</h6>
              <label>
                Add a request note (optional)
                <br />
                <input type="text" value={requestNote} onChange={(e) => setRequestNote(e.target.value)} />
              </label>
              <button onClick={() => handlers.handleArchivalRequestGeneration(selectedResult, requestNote)}>Submit</button>
              <div>
                <p>{responseMessage} {responseStatus}</p>
                <button onClick={() => {
                  setShowArchivalModal(false);
                  setResponseStatus('');
                  setResponseMessage('');
                  setIsLoading(false);
                }}>Close</button>
              </div>
            </Modal>
          )}
        </div>

        {isChartModalOpen && <ViewerChartModal viewerData={viewerData} closeModal={() => setIsChartModalOpen(false)} />}
      </div>
    </>
  );
};

export default RouteStreams;
