
export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
};

export const handleYesterdayClick = (setStartDate, setEndDate, setActiveButton, setRunSearch) => {
  const today = new Date();
  const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
  setStartDate(formatDate(yesterday));
  setEndDate(formatDate(today));
  setActiveButton('24-hour');
  setRunSearch(true);
};

export const handleSevenDaysClick = (setStartDate, setEndDate, setActiveButton, setRunSearch) => {
  const today = new Date();
  const sevenDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
  setStartDate(formatDate(sevenDaysAgo));
  setEndDate(formatDate(today));
  setActiveButton('7-day');
  setRunSearch(true);
};

export const handleThirtyDaysClick = (setStartDate, setEndDate, setActiveButton) => {
  const today = new Date();
  const thirtyDaysAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
  setStartDate(formatDate(thirtyDaysAgo));
  setEndDate(formatDate(today));
  setActiveButton('30-day');
};

export const handleOneYearClick = (setStartDate, setEndDate, setActiveButton) => {
  const today = new Date();
  const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
  setStartDate(formatDate(oneYearAgo));
  setEndDate(formatDate(today));
  setActiveButton('365-day');
};

export const handleAllTimeClick = (setStartDate, setEndDate, setActiveButton) => {
  const today = new Date();
  const allTimeAgo = new Date(today.getFullYear() - 10, today.getMonth(), today.getDate());
  setStartDate(formatDate(allTimeAgo));
  setEndDate(formatDate(today));
  setActiveButton('all-time');
};

export const handleInputChange = (e, setInputText) => {
  setInputText(e.target.value);
};

export const handleInputChange2 = (e, setInputText2) => {
  setInputText2(e.target.value);
};

export const handleSearchClick = (inputText, inputText2, selectedChannel, startDate, endDate, onSearch) => {
  const searchParams = {
    searchText: inputText,
    searchText2: inputText2,
    channel_nickname: selectedChannel,
    startDate,
    endDate
  };
  onSearch(searchParams);
};

export const handleKeyPress = (e, handleSearchClick) => {
  if (e.key === 'Enter') {
    handleSearchClick();
  }
};

export const handleFilterChange = (event, setCurrentPage, results, setFilteredResults, setFilterText) => {
  const newFilterText = event.target.value.toLowerCase();
  setCurrentPage(1);
  if (newFilterText) {
    setFilteredResults(
      results.filter(result => 
        result.highlight && result.highlight.toLowerCase().includes(newFilterText)
      )
    );
  } else {
    setFilteredResults(results);
  }
  setFilterText(newFilterText);
};

export const handleRowClick = (result, event, setVideoUrl) => {
  if (event.target.tagName !== 'A' && event.target.tagName !== 'BUTTON') {
    const timestampedUrl = `https://youtu.be/${result.video_id}?t=${result.start_time}`;
    setVideoUrl(timestampedUrl);
  }
};

export const handleDownloadClip = (clipUrl, customFilename) => {
  const downloadUrl = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/clip-download?url=${encodeURIComponent(clipUrl)}&filename=${encodeURIComponent(customFilename)}`;
  window.open(downloadUrl);
};

export const handleDownloadIconClick = (event, setShowFilenameModal) => {
  event.stopPropagation();
  setShowFilenameModal(true);
};

export const handleYoutubeUploadClick = (event, setCameFromClipModal, setShowYoutubeUploadModal) => {
  event.stopPropagation();
  setCameFromClipModal(true);
  setShowYoutubeUploadModal(true);
};

export const handleViewClip = (clipFilename) => {
  fetch(`https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/get_clip?filename=${clipFilename}`)
    .then((response) => response.json())
    .then((data) => {
      window.open(data.url);
    })
    .catch((error) => {
      console.error('Error viewing clip:', error);
    });
};

export const handleClipButtonClick = (event, result, setSelectedResult, setClipModalOpen) => {
  event.stopPropagation();
  setSelectedResult(result);
  setClipModalOpen(true);
};

export const handleTranscriptGeneration = (event, result) => {
  event.stopPropagation();
  const video_id = result.video_id;
  const start_time = result.start_time;
  const adjusted_start_time = start_time;
  const filename = `transcript_${video_id}_${start_time}.txt`;

  const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/get_transcript?videoid=${video_id}&start=${adjusted_start_time}`;
  fetch(url)
    .then((response) => {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
      });
    })
    .catch((error) => {
      console.error('Error downloading file:', error);
    });
};

export const handleArchivalRequest = (event, result, setSelectedResult, setRequestNote, setResponseMessage, setResponseStatus, setArchivalModalOpen, defaultRequestNote) => {
  event.stopPropagation();
  setSelectedResult(result);
  setRequestNote(defaultRequestNote);
  setResponseMessage('');
  setResponseStatus('');
  setArchivalModalOpen(true);
};

export const handleArchivalRequestGeneration = (result, requestNote, user, setResponseMessage, setResponseStatus) => {
  const video_id = result.video_id;
  const channel = result.channel_nickname;
  const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/request-archival?videoid=${video_id}&username=${user}&channel_nickname=${channel}&note=${requestNote}`;

  fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      const { message, status } = data;
      setResponseMessage(message);
      setResponseStatus(status);
    })
    .catch((error) => {
      console.error('Error requesting archival:', error);
    });
};

export const handleClipGeneration = (result, startTimeOffset, clipDuration, audioOnly, setIsLoading, setClipUrl, setShowClipModal) => {
  if (result) {
    setIsLoading(true);
    const video_id = result.video_id;
    const channel = result.channel_nickname;
    const start_time = result.start_time;
    const adjusted_start_time = start_time + Number(startTimeOffset);
    const duration = clipDuration;
    
    const url = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/clip?channel=${channel}&videoid=${video_id}&start=${adjusted_start_time}&duration=${duration}&audio_only=${audioOnly}`;
    
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setClipUrl(data.url);
        setShowClipModal(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error generating clip:', error);
        setIsLoading(false);
      });
  }
};

export const handleChannelNicknameCheckboxChange = (channelNickname, setSelectedChannelNicknames) => {
  setSelectedChannelNicknames(prevSelectedChannelNicknames => {
    const updatedSelectedChannelNicknames = new Set(prevSelectedChannelNicknames);
    if (updatedSelectedChannelNicknames.has(channelNickname)) {
      updatedSelectedChannelNicknames.delete(channelNickname);
    } else {
      updatedSelectedChannelNicknames.add(channelNickname);
    }
    return updatedSelectedChannelNicknames;
  });
};

export const uploadToYoutube = (formData, clipUrl, setShowYoutubeUploadModal) => {
  const { video_title, video_description, video_type, schedule_time, hashtags, channel } = formData;
  const s3url = encodeURIComponent(clipUrl);
  const encodedTitle = encodeURIComponent(video_title);
  const encodedDescription = encodeURIComponent(video_description);
  const encodedHashtags = encodeURIComponent(hashtags);
  
  const apiUrl = `https://nl0hjor2ok.execute-api.us-west-2.amazonaws.com/publish_to_youtube?s3url=${s3url}&channel=${channel}&title=${encodedTitle}&description=${encodedDescription}&start_time=${schedule_time}&video_type=${video_type}&hashtags=${encodedHashtags}`;

  fetch(apiUrl)
    .then(response => response.json())
    .then(data => {
      console.log('Upload response:', data);
      setShowYoutubeUploadModal(false);
    })
    .catch(error => {
      console.error('Error uploading to YouTube:', error);
    });
};

// Pagination handlers
export const handlePreviousPageClick = (event, currentPage, setCurrentPage) => {
  event.preventDefault();
  event.stopPropagation();
  if (currentPage > 1) {
    setCurrentPage(currentPage - 1);
  }
};

export const handlePreviousPageBeginningClick = (event, currentPage, setCurrentPage) => {
  event.preventDefault();
  event.stopPropagation();
  if (currentPage > 1) {
    setCurrentPage(1);
  }
};

export const handleNextPageClick = (event, currentPage, totalPagesFiltered, setCurrentPage) => {
  event.preventDefault();
  event.stopPropagation();
  if (currentPage < totalPagesFiltered) {
    setCurrentPage(currentPage + 1);
  }
};

export const handleNextPageEndClick = (event, totalPagesFiltered, setCurrentPage) => {
  event.preventDefault();
  event.stopPropagation();
  setCurrentPage(totalPagesFiltered);
};

export const handleCloseYoutubeUploadModal = (setShowYoutubeUploadModal, cameFromClipModal, setShowClipModal, setCameFromClipModal) => {
  setShowYoutubeUploadModal(false);
  if (cameFromClipModal) {
    setShowClipModal(true);
    setCameFromClipModal(false);
  }
};